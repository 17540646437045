import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {apiRequest} from "../../utils/request";
import {
  DATE_SEARCH_FORMAT,
  DATE_SHORT_FORMAT,
  FABRIC_RECOVER_ORDER_STATUS_QUOTE,
  FabricRecoverOrderStatuses,
  INVOICE_TYPE_FABRICRECOVER,
  isNullEmptyOrWhitespace,
  obsfucate, OrderStatuses, UrlSegmentArray, UrlSegmentString,
} from "../../constants";
import moment from "moment";
import ReactTable from "react-table";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";

const initState = {
  orders: [],
  columns: [],
  filter: {
    sequenceNumber: null, 
    customer: null,
    customerReferenceNumber: null,
    invoiceNumber: null,
    powderCoater: null, 
    fabricMaker: null, 
    product: [],
    createdDateFrom: null,
    createdDateTo: null,
  },
};

const FilterProducts = [
  { label: 'Arms', value: 'Arm' },
  { label: 'AU', value: 'AU' },
  { label: 'CX', value: 'CX' },
  { label: 'DS', value: 'DS' },
  { label: 'EC', value: 'EC' },
  { label: 'MK', value: 'MK' },
  { label: 'OL', value: 'OL' },
  { label: 'OS', value: 'OS' },
  { label: 'PA', value: 'PA' },
  { label: 'Parts', value: 'Parts' },
  { label: 'Recover', value: 'Recover' },
  { label: 'S1', value: 'S1' },
  { label: 'S3', value: 'S3' },
  { label: 'SD', value: 'SD' },
  { label: 'SD CP', value: 'SD CP' },
  { label: 'SD SA', value: 'SD SA' },
  { label: 'SM', value: 'SM' },
  { label: 'SP', value: 'SP' },
  { label: 'SP S3', value: 'SP S3' },
  { label: 'ST', value: 'ST' },
  { label: 'TG', value: 'TG' },
  { label: 'TG OS', value: 'TG OS' },
  { label: 'TG VV', value: 'TG VV' },
  { label: 'VR', value: 'VR' },
  { label: 'VS', value: 'VS' },
  { label: 'VV S1', value: 'VV S1' },
  { label: 'WF', value: 'WF' },
  { label: 'ZT', value: 'ZT' }
];

const years = [{ label: '1900', value:'1900'},
  { label: '2000', value:'2000'},
  { label: '2005', value:'2005'},
  { label: '2006', value:'2006'},
  { label: '2007', value:'2007'},
  { label: '2008', value:'2008'},
  { label: '2009', value:'2009'},
  { label: '2010', value:'2010'},
  { label: '2011', value:'2011'},
  { label: '2012', value:'2012'},
  { label: '2013', value:'2013'},
  { label: '2014', value:'2014'},
  { label: '2015', value:'2015'},
  { label: '2016', value:'2016'},
  { label: '2017', value:'2017'},
  { label: '2018', value:'2018'},
  { label: '2019', value:'2019'},
  { label: '2020', value:'2020'}];

const customers = [
  { label: "A Style of Shade", value:"A Style of Shade"},
  { label: "Aalta", value:"Aalta"},
  { label: "Abesco", value:"Abesco"},
  { label: "Absol", value:"Absol"},
  { label: "Accent Blinds", value:"Accent Blinds"},
  { label: "Ace of Shades", value:"Ace of Shades"},
  { label: "ACT Shade", value:"ACT Shade"},
  { label: "Addshade", value:"Addshade"},
  { label: "Adelaide Awnings", value:"Adelaide Awnings"},
  { label: "Ajac", value:"Ajac"},
  { label: "Alfresco", value:"Alfresco"},
  { label: "All Clean Blinds", value:"All Clean Blinds"},
  { label: "All Installations", value:"All Installations"},
  { label: "Amazing Clean", value:"Amazing Clean"},
  { label: "Andaluz", value:"Andaluz"},
  { label: "Artech Blinds", value:"Artech Blinds"},
  { label: "Attractive Windows", value:"Attractive Windows"},
  { label: "Aussie Blinds", value:"Aussie Blinds"},
  { label: "Australian Shutters + Blinds", value:"Australian Shutters + Blinds"},
  { label: "Aviance Blinds", value:"Aviance Blinds"},
  { label: "Awesome Awnings", value:"Awesome Awnings"},
  { label: "AWN8 Fittings", value:"AWN8 Fittings"},
  { label: "Awning by Design", value:"Awning by Design"},
  { label: "Ayerys Rock", value:"Ayerys Rock"},
  { label: "BAC Wholesales", value:"BAC Wholesales"},
  { label: "Bay Blinds", value:"Bay Blinds"},
  { label: "Bayside Shutters", value:"Bayside Shutters"},
  { label: "Behrens", value:"Behrens"},
  { label: "Blind 'n Good Shutters", value:"Blind 'n Good Shutters"},
  { label: "Blind Inspiration", value:"Blind Inspiration"},
  { label: "Blindcraft", value:"Blindcraft"},
  { label: "Blindmaster", value:"Blindmaster"},
  { label: "Blinds at Willoughby", value:"Blinds at Willoughby"},
  { label: "Blinds by McColl", value:"Blinds by McColl"},
  { label: "Blinds by Peter Meyer", value:"Blinds by Peter Meyer"},
  { label: "Blinds in Mind", value:"Blinds in Mind"},
  { label: "Blinds in Style", value:"Blinds in Style"},
  { label: "Blindtime", value:"Blindtime"},
  { label: "Blue Peter", value:"Blue Peter"},
  { label: "Blue Sky Shutters", value:"Blue Sky Shutters"},
  { label: "Blush Interiors", value:"Blush Interiors"},
  { label: "Bright Blinds", value:"Bright Blinds"},
  { label: "Bushs Blinds", value:"Bushs Blinds"},
  { label: "C + H", value:"C + H"},
  { label: "Camerons", value:"Camerons"},
  { label: "Canberra Blinds", value:"Canberra Blinds"},
  { label: "Central Installations", value:"Central Installations"},
  { label: "Clear Blinds Australia", value:"Clear Blinds Australia"},
  { label: "Clearview", value:"Clearview"},
  { label: "CMS", value:"CMS"},
  { label: "Colourful Canvas", value:"Colourful Canvas"},
  { label: "Colourfull Canvas", value:"Colourfull Canvas"},
  { label: "Colt Tollfab", value:"Colt Tollfab"},
  { label: "Complete Blinds", value:"Complete Blinds"},
  { label: "Creative Blinds", value:"Creative Blinds"},
  { label: "Creative Canvas", value:"Creative Canvas"},
  { label: "Creative Covers", value:"Creative Covers"},
  { label: "Creative Windows", value:"Creative Windows"},
  { label: "Crystal Image Blinds", value:"Crystal Image Blinds"},
  { label: "Crystal Twig", value:"Crystal Twig"},
  { label: "CX", value:"CX"},
  { label: "D+G Installations", value:"D+G Installations"},
  { label: "Dalekit", value:"Dalekit"},
  { label: "Debonair", value:"Debonair"},
  { label: "Decks by Design", value:"Decks by Design"},
  { label: "Decorama", value:"Decorama"},
  { label: "Delta Shopfittings", value:"Delta Shopfittings"},
  { label: "Design Curtains", value:"Design Curtains"},
  { label: "Drummond Blinds", value:"Drummond Blinds"},
  { label: "Dunn + Pilcher", value:"Dunn + Pilcher"},
  { label: "Duolux", value:"Duolux"},
  { label: "Dynamic Additions", value:"Dynamic Additions"},
  { label: "Eastcoast Awnings", value:"Eastcoast Awnings"},
  { label: "Eastcoast Shade Design", value:"Eastcoast Shade Design"},
  { label: "Easy Blinds", value:"Easy Blinds"},
  { label: "Eclipse", value:"Eclipse"},
  { label: "Energy Window Fashion", value:"Energy Window Fashion"},
  { label: "Erebus", value:"Erebus"},
  { label: "Essendia Blinds", value:"Essendia Blinds"},
  { label: "Evolution Blinds", value:"Evolution Blinds"},
  { label: "Fashion Floors", value:"Fashion Floors"},
  { label: "Fashion Windows", value:"Fashion Windows"},
  { label: "Foldaway", value:"Foldaway"},
  { label: "Foreshore Property", value:"Foreshore Property"},
  { label: "Foster Tuncurry Awnings", value:"Foster Tuncurry Awnings"},
  { label: "Gecco Blinds", value:"Gecco Blinds"},
  { label: "GFS", value:"GFS"},
  { label: "Goldenwest", value:"Goldenwest"},
  { label: "Grovewood", value:"Grovewood"},
  { label: "Hardback", value:"Hardback"},
  { label: "Harvey Blinds", value:"Harvey Blinds"},
  { label: "Helioscreen", value:"Helioscreen"},
  { label: "Heritage Blinds", value:"Heritage Blinds"},
  { label: "Highland Blinds", value:"Highland Blinds"},
  { label: "Home Improvements", value:"Home Improvements"},
  { label: "Homelife", value:"Homelife"},
  { label: "Hornsby Blinds", value:"Hornsby Blinds"},
  { label: "Hunter Valley Blinds", value:"Hunter Valley Blinds"},
  { label: "Illawarra", value:"Illawarra"},
  { label: "Image Blinds", value:"Image Blinds"},
  { label: "In Blinds", value:"In Blinds"},
  { label: "Innovation Services", value:"Innovation Services"},
  { label: "Integra", value:"Integra"},
  { label: "Interior Solutions", value:"Interior Solutions"},
  { label: "Issey", value:"Issey"},
  { label: "Jace of Shades", value:"Jace of Shades"},
  { label: "Jomo", value:"Jomo"},
  { label: "Kell + Rigby", value:"Kell + Rigby"},
  { label: "Kingsford Blinds", value:"Kingsford Blinds"},
  { label: "Kiwi-Fit", value:"Kiwi-Fit"},
  { label: "Kolibrietech", value:"Kolibrietech"},
  { label: "Lakeview Blinds", value:"Lakeview Blinds"},
  { label: "Lidi Group", value:"Lidi Group"},
  { label: "Lifestyle Awnings", value:"Lifestyle Awnings"},
  { label: "Litemaster", value:"Litemaster"},
  { label: "Lucas Binds", value:"Lucas Binds"},
  { label: "Lucas Blinds", value:"Lucas Blinds"},
  { label: "Macarthur Home", value:"Macarthur Home"},
  { label: "Maintheme Blinds", value:"Maintheme Blinds"},
  { label: "Mal Glanville", value:"Mal Glanville"},
  { label: "Mansours", value:"Mansours"},
  { label: "Master Quality Shade", value:"Master Quality Shade"},
  { label: "Matiz", value:"Matiz"},
  { label: "Matraville Blinds", value:"Matraville Blinds"},
  { label: "Maxim", value:"Maxim"},
  { label: "Melbourne Awning", value:"Melbourne Awning"},
  { label: "Melbourne External Shade", value:"Melbourne External Shade"},
  { label: "Melbourne Shade", value:"Melbourne Shade"},
  { label: "Menai Blinds", value:"Menai Blinds"},
  { label: "Merilyn By Design", value:"Merilyn By Design"},
  { label: "Metaic", value:"Metaic"},
  { label: "Midcoast Blinds", value:"Midcoast Blinds"},
  { label: "Northern Beaches", value:"Northern Beaches"},
  { label: "Not used", value:"Not used"},
  { label: "Not used: 18230 to 18329", value:"Not used: 18230 to 18329"},
  { label: "Not used: 18340 to 18339", value:"Not used: 18340 to 18339"},
  { label: "Not used: 18450 to 18549", value:"Not used: 18450 to 18549"},
  { label: "Not used: 21330 to 21339", value:"Not used: 21330 to 21339"},
  { label: "NQ Shade", value:"NQ Shade"},
  { label: "Nuevue", value:"Nuevue"},
  { label: "Only Awnings", value:"Only Awnings"},
  { label: "Onyx", value:"Onyx"},
  { label: "Outdoor Blinds Group", value:"Outdoor Blinds Group"},
  { label: "Outrigger", value:"Outrigger"},
  { label: "Oz Awnings", value:"Oz Awnings"},
  { label: "Ozsun", value:"Ozsun"},
  { label: "Paddington Shutters", value:"Paddington Shutters"},
  { label: "Perfect Blinds", value:"Perfect Blinds"},
  { label: "Pestana Shutters", value:"Pestana Shutters"},
  { label: "PiPi Blinds + Awnings", value:"PiPi Blinds + Awnings"},
  { label: "Premier Shades", value:"Premier Shades"},
  { label: "Prime Shade", value:"Prime Shade"},
  { label: "Quality Blinds", value:"Quality Blinds"},
  { label: "Quickfix", value:"Quickfix"},
  { label: "Retail", value:"Retail"},
  { label: "Riteway", value:"Riteway"},
  { label: "Sanctuary Shade", value:"Sanctuary Shade"},
  { label: "Screen N Shade", value:"Screen N Shade"},
  { label: "Screen Technics", value:"Screen Technics"},
  { label: "SCS Blinds", value:"SCS Blinds"},
  { label: "Seaforth Blinds", value:"Seaforth Blinds"},
  { label: "Shade Australia", value:"Shade Australia"},
  { label: "Shade Factor", value:"Shade Factor"},
  { label: "Shade for Life", value:"Shade for Life"},
  { label: "Shade Industries", value:"Shade Industries"},
  { label: "Shade Pro", value:"Shade Pro"},
  { label: "Shade X", value:"Shade X"},
  { label: "ShadeCo", value:"ShadeCo"},
  { label: "Shaded Measure", value:"Shaded Measure"},
  { label: "Shadeline", value:"Shadeline"},
  { label: "Shaderite", value:"Shaderite"},
  { label: "Shades + Shutters", value:"Shades + Shutters"},
  { label: "Shades of Australia", value:"Shades of Australia"},
  { label: "Shadewell", value:"Shadewell"},
  { label: "Shadex", value:"Shadex"},
  { label: "Shading Systems", value:"Shading Systems"},
  { label: "Sheerview", value:"Sheerview"},
  { label: "Shutter Up", value:"Shutter Up"},
  { label: "Simple Studios", value:"Simple Studios"},
  { label: "Simpson's Blinds", value:"Simpson's Blinds"},
  { label: "Sky Max", value:"Sky Max"},
  { label: "Smart Canvas", value:"Smart Canvas"},
  { label: "Solis Products", value:"Solis Products"},
  { label: "Somfy", value:"Somfy"},
  { label: "Southern Blinds", value:"Southern Blinds"},
  { label: "Space + Shade", value:"Space + Shade"},
  { label: "Stan Bond", value:"Stan Bond"},
  { label: "Straightline", value:"Straightline"},
  { label: "Structures by Design", value:"Structures by Design"},
  { label: "Stuart Blinds", value:"Stuart Blinds"},
  { label: "Sunlight Products", value:"Sunlight Products"},
  { label: "Sunliving", value:"Sunliving"},
  { label: "Sunmaster", value:"Sunmaster"},
  { label: "Sunshine Living", value:"Sunshine Living"},
  { label: "Sunstate", value:"Sunstate"},
  { label: "Sunstopper", value:"Sunstopper"},
  { label: "Sunteca", value:"Sunteca"},
  { label: "Sunwise", value:"Sunwise"},
  { label: "Supershades", value:"Supershades"},
  { label: "Sydney Blinds", value:"Sydney Blinds"},
  { label: "Sydney Curtains & Blinds", value:"Sydney Curtains & Blinds"},
  { label: "Taren Point", value:"Taren Point"},
  { label: "Taylor and Stirling", value:"Taylor and Stirling"},
  { label: "Techmark", value:"Techmark"},
  { label: "Technoart", value:"Technoart"},
  { label: "Tempo Interiors", value:"Tempo Interiors"},
  { label: "Tempo Interios", value:"Tempo Interios"},
  { label: "Tip Top Blinds", value:"Tip Top Blinds"},
  { label: "To Shades", value:"To Shades"},
  { label: "Top Fit", value:"Top Fit"},
  { label: "Trilogy", value:"Trilogy"},
  { label: "Tropic'l Blinds", value:"Tropic'l Blinds"},
  { label: "Troys A2Z", value:"Troys A2Z"},
  { label: "Turner Arc", value:"Turner Arc"},
  { label: "Turner Bros", value:"Turner Bros"},
  { label: "Turner Bros.", value:"Turner Bros."},
  { label: "Twenty20 Blinds", value:"Twenty20 Blinds"},
  { label: "TWT", value:"TWT"},
  { label: "Ulladulla", value:"Ulladulla"},
  { label: "Undercover", value:"Undercover"},
  { label: "Unique Curtains", value:"Unique Curtains"},
  { label: "Viva", value:"Viva"},
  { label: "Wagga Blinds", value:"Wagga Blinds"},
  { label: "Watson", value:"Watson"},
  { label: "Well Hung Awnings", value:"Well Hung Awnings"},
  { label: "Window Furnishing Designs", value:"Window Furnishing Designs"},
  { label: "Xstrata", value:"Xstrata"},
  { label: "Yarra Shade", value:"Yarra Shade"},
  { label: "Ziptrack [Fischer]", value:"Ziptrack [Fischer]"},  
];

class LegacyOrdersPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {...initState};

    this.fetchOrders = this.fetchOrders.bind(this);   
    this.setColumns = this.setColumns.bind(this);
    this.updateFilterField = this.updateFilterField.bind(this);
    this.updateFilterFieldWithValue = this.updateFilterFieldWithValue.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
  }

  async fetchOrders() {

    let {filter} = this.state;
    
    let url = `/api/legacyOrders?`;

    url += UrlSegmentString('sequenceNumber', filter.sequenceNumber);
    url += UrlSegmentString('customer', filter.customer);
    url += UrlSegmentString('customerReferenceNumber', filter.customerReferenceNumber);
    url += UrlSegmentString('invoiceNumber', filter.invoiceNumber);
    url += UrlSegmentString('powderCoater', filter.powderCoater);
    url += UrlSegmentString('fabricMaker', filter.fabricMaker);
    url += UrlSegmentArray('product', filter.product);
    url += UrlSegmentString('createdDateFrom', isNullEmptyOrWhitespace(filter.createdDateFrom) ? null : moment(filter.createdDateFrom).format(DATE_SEARCH_FORMAT));
    url += UrlSegmentString('createdDateTo', isNullEmptyOrWhitespace(filter.createdDateTo) ? null : moment(filter.createdDateTo).format(DATE_SEARCH_FORMAT));
    
    let orders = (await apiRequest(url)).data;
    this.setState({
      orders,
    }, () => {
      this.setColumns()
    });
  }

  setColumns() {
    this.setState({
      columns: [{id: 'sequenceNumber', Header: 'Prod. No.', 
        accessor: d => d.sequenceNumber,
        sortMethod: (a, b) => {
          let aa = a.indexOf(".") > 0 ? a.substring(0, a.indexOf(".")) : a;
          let bb = b.indexOf(".") > 0 ? b.substring(0, b.indexOf(".")) : b;
                  
          let aVal = parseInt(aa);
          let bVal =  parseInt(bb);

          return aVal < bVal ? -1 : 1;
        },
      },
        {
          id: 'createdDate', 
          Header: 'Created Date', 
          accessor: d => moment(d.createdDate).format(DATE_SHORT_FORMAT),
          sortMethod: (a, b) => {
            let aVal = moment(a, DATE_SHORT_FORMAT);
            let bVal = moment(b, DATE_SHORT_FORMAT);

            return aVal.isBefore(bVal) ? -1 : 1;
          },
        },
        {id: 'customer', Header: 'Customer', accessor: d => d.customer,},
        {id: 'customerReferenceNumber', Header: 'CRN', accessor: d => d.customerReferenceNumber,},
        {id: 'invoiceNumber', Header: 'Inv. No.', accessor: d => d.invoiceNumber,},
        {id: 'product', Header: 'Product', accessor: d => d.product,},
        {id: 'width', Header: 'Width', accessor: d => d.width,},
        {id: 'projection', Header: 'Projection', accessor: d => d.projection,},
        {id: 'pcColour', Header: 'Colour', accessor: d => d.pcColour,},
        {id: 'pcColour2', Header: 'Colour 2', accessor: d => d.pcColour2,},
        {id: 'operation', Header: 'Operation', accessor: d => d.operation,},
        {id: 'motorType', Header: 'Motor Type', accessor: d => d.motorType,},
        {id: 'automation', Header: 'Automation', accessor: d => d.automation,}
      ],
    });
  }

  updateFilterField(e){
    let {filter} = this.state;    
    
    filter[e.target.name] = e.target.value;
    
    this.setState({filter});
  }

  updateFilterFieldWithValue(val, fieldName = null){
    let {filter} = this.state;

    filter[fieldName] = val;

    this.setState({filter});
  }
  
  resetFilters(e){
    console.log("hello");
    e.preventDefault();   
    this.setState({filter: {
        sequenceNumber: null,
        customer: null,
        customerReferenceNumber: null,
        invoiceNumber: null,
        powderCoater: null,
        fabricMaker: null,
        product: [],
        createdDateFrom: null,
        createdDateTo: null,
      }});
  }

  render() {

    let {user,} = this.props;
    let {orders, columns, filter} = this.state;

    return (
      <div>
        <div className={"row mt-2 mb-3"}>
          <div className={"col-12"}>
            <h2>Legacy Database | <Link to={"/legacyOrdersSintesi/list"}>Legacy
              Sintesi Database</Link></h2>
          </div>
        </div>

        <div className={"row mt-2"}>
          <div className={"col-12"}>
          <form>
              <div className="form-group row">
                <div className="col-3">
                  <input type={'string'}
                         className={'form-control'}
                         value={filter.sequenceNumber || ""}
                         onChange={this.updateFilterField}                         
                         name={'sequenceNumber'}
                         placeholder={'Prod. No.'} />
                </div>
                <div className="col-3">
                  <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      multi={false}
                      labelText="Customer"
                      name="customer"
                      options={customers}
                      onChange={(val) => this.updateFilterFieldWithValue(val, "customer")}
                      simpleValue={true}
                      value={filter.customer || ""}
                      placeholder={'Customer'}
                  />
                </div>
                <div className="col-3">
                  <input type={'string'}
                         className={'form-control'}
                         value={filter.customerReferenceNumber || ""}
                         onChange={this.updateFilterField}
                         name={'customerReferenceNumber'}
                         placeholder={'CRN'} />
                </div>
                <div className="col-3">                  
                  <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      multi={true}
                      labelText="Product"
                      name="product"
                      options={FilterProducts}
                      onChange={(val) => 
                          this.updateFilterFieldWithValue(isNullEmptyOrWhitespace(val) ? [] : val.split(',').map(x => x), "product")}
                      simpleValue={true}
                      value={filter.product}
                      placeholder={'Product'}
                  />                
                </div>
              </div>             
              
              <div className="form-group row">
                <div className="col-3">
                  <input type={'string'}
                         className={'form-control'}
                         value={filter.invoiceNumber || ""}
                         onChange={this.updateFilterField}
                         name={'invoiceNumber'}
                         placeholder={'Inv. No.'} />
                </div>
                <div className={'col-sm-3'}>
                  <DatePicker
                      selected={filter.createdDateFrom}
                      selectsStart
                      startDate={filter.createdDateFrom}
                      endDate={filter.createdDateTo}
                      onChange={val => this.updateFilterFieldWithValue(val, 'createdDateFrom')}
                      className={'form-control'}
                      dateFormat={DATE_SHORT_FORMAT}
                      placeholderText={'Date From'}
                      dropdownMode={"scroll"}
                  /><br />
                  <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      multi={false}
                      labelText="Year From"
                      name="yearFrom"
                      options={years}
                      onChange={(val) => this.updateFilterFieldWithValue(isNullEmptyOrWhitespace(val) ? null :
                          moment(`${val}-01-01`), "createdDateFrom")}
                      simpleValue={true}
                      value={filter.createdDateFrom === null ? null : moment(filter.createdDateFrom).year().toString()}
                      placeholder={'Year From'}
                  />
                </div>
                <div className={'col-sm-3'}>
                  <DatePicker
                      selected={filter.createdDateTo}
                      selectsEnd
                      startDate={filter.createdDateFrom}
                      endDate={filter.createdDateTo}
                      onChange={val => this.updateFilterFieldWithValue(val, 'createdDateTo')}
                      className={'form-control'}
                      dateFormat={DATE_SHORT_FORMAT}
                      placeholderText={'Date To'}
                      dropdownMode={"scroll"}
                  /><br />
                  <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      multi={false}
                      labelText="Year To"
                      name="yearTo"
                      options={years}
                      onChange={(val) => this.updateFilterFieldWithValue(isNullEmptyOrWhitespace(val) ? null :
                          moment(`${val}-12-31`), "createdDateTo")}
                      simpleValue={true}
                      value={filter.createdDateTo === null ? null : moment(filter.createdDateTo).year().toString()}
                      placeholder={'Year To'}
                  /> 
                  
                </div>
                <div className={"col-3 text-right"}>
                  <div className="form-group">
                    <button                        
                        onClick={this.resetFilters}
                        type="button"
                        className="btn pi-btn pi-uppercase mr-3">
                      <i className="fa fa-times" />&nbsp;Reset
                    </button>
                    <button
                        onClick={async (e)=>{
                          e.preventDefault();
                          await this.fetchOrders();
                        }}
                        type="submit"
                        className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing"
                    >
                      <i className="icon-floppy pi-icon-left"/>&nbsp;Search
                    </button>
                  </div>
                </div>                
              </div>             
              
            </form>
          </div>
        </div>
        
        <div className={"row mt-2 mb-4"}>
          <div className={"col-12"}>
            {orders.length >0 && <p><i>Found {orders.length} results {orders.length === 100 ? '(results are limited to the top 100)' : ''}</i></p> }
            <ReactTable                
                filterable={false}
                data={orders}
                columns={columns}
            />
          </div>
        </div>
        <div className={"row mt-4 mb-4"}>
          <div className={"col-12"}>&nbsp;</div>
        </div>
        
      </div>
    );
  }
}

LegacyOrdersPage.propTypes = {
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {

  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LegacyOrdersPage));