import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextViewControl from '../common/TextViewControl';
import TextViewControlCutting from '../common/TextViewControlCutting';
import {
  DATE_SHORT_FORMAT,
  isSelectaPitch,
  FABRIC_SUPPLIED_BY_ALUXOR,
  isVarioVolant,
  SpaceString,
  FABRIC_SUPPLIED_CUSTOMER,
  FABRIC_SUPPLIED_NONE,
  isOl,
  isDs,
  isManual,
  DISPATCH_COLLECT,
  PRODUCTID_OLYMPUS,
  Aux,
  isNullEmptyOrWhitespace,
  IsNullOrUndefined,
  PRODUCTGROUPID_CONSERVATORY,
  isSteerAround,
  FRAME_FEATURE_SA,
  FRAME_FEATURE_SACP,
  isRemote,
  AwningRequiresFabric,
  isSDSM,
  AwningBracketTotal,
  PRODUCTGROUPID_FOLDING_ARM,
  PRODUCTID_TOGA,
  FLASHINGTYPE_WallSeal,
  PRODUCTID_SD,
  PRODUCTID_SDM,
  PRODUCTID_SDC,
  PRODUCTID_SDCZ,
  REMOTE_MULTICHANNEL,
  PRODUCTID_DS,
  isRemoteFrameFeature,
  FRAME_FEATURE_VARIOVOLANT,
  PRODUCTGROUPID_ROOFING_SYSTEMS,
  SD_CONTINUOUS_ANGLE_BRACKET_ID,
} from '../../constants';
import moment from 'moment';
import {SummaryDivider} from '../common/SummaryControls';
import injectSheet from 'react-jss';
import AwningSADimensionSummary from "./AwningSADimensionSummary";
import AwningCuttingDeductionsControl from "../common/AwningCuttingDeductionsControl";

const styles = {
  checkOffBox: {
    border: '2px solid #2e343c',
    width: '14px',
    height: '14px',
    display: 'inline-flex',
    alignItems: 'center',
  },
};

const ColourText = (props) => {
  let {labelText, standardColour, customColour, style, col1, col2} = props;
  col1 = IsNullOrUndefined(col1) ? 4 : col1;
  col2 = IsNullOrUndefined(col2) ? 8 : col2;
  return (
      <div className="pi-row" style={style}>
        <div className={`pi-col-sm-${col1}`}>{labelText}</div>
        <div className={`pi-col-sm-${col2}`}>{customColour === null ? standardColour : customColour}</div>
      </div>
  );
};

class AwningProductionSheet extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    let {awning, order, classes} = this.props;
    let awningCount = order.awnings.length;
    let awningRequiresFabric = AwningRequiresFabric(order, awning);
    let {bracketTotal, bracketTotalDualMount, bracketTotalSingleMount} = AwningBracketTotal(awning);

    let thisByThat = (x, y) => {
      return y > 1 ? `${x} x ${y}` : x;
    };
    let is70mmROller = awning && awning.productId === PRODUCTID_OLYMPUS && awning.projection === 3000;

    return awning && awning.id > 0 ? (
      <div>
        <div className="pi-row" style={{marginBottom: '15px', marginTop: '25px'}}>
          <div className="pi-col-sm-4">
            <h4><img src="img/logo-full.png" alt="Aluxor Logo"></img></h4>
          </div>
          <div className="pi-col-sm-2"></div>
          <div className="pi-col-sm-2" style={{textAlign: 'right', padding: '10px', fontWeight: 'bold' }}>
            ALX Ref
          </div>
          <div className="pi-col-sm-2" style={{padding: '8px', border: '2px solid #2e343c', fontWeight: 'bold'}}>
            {order.sequenceNumber}.{awning.number}
          </div>
          <div className="pi-col-sm-1" style={{padding: '8px', borderRight: '2px solid #2e343c', borderBottom: '2px solid #2e343c', borderTop: '2px solid #2e343c', fontWeight: 'bold'}}>
            {SpaceString([awning.productShortName, awning.frameFeatureShortName, awning.oversquare ? 'OS' : null], '/')}
          </div>
        </div>

        <div className="pi-row" style={{borderTop: '2px solid #2e343c'}} ref="customer_details">
          <div className="pi-col-sm-5">
            <div className="pi-section" style={{paddingLeft: '5px', paddingTop: '15px', paddingBottom: '15px', paddingRight: '0px'}}>
              <TextViewControl labelText={'Customer'} value={order.organisationDescription} />
              <TextViewControl labelText={'Order Ref'} value={order.customerReference} />
              <TextViewControl labelText={'Order Date'} value={moment(order.orderDisplayDate).format(DATE_SHORT_FORMAT)} />
            </div>
          </div>

          <div className="pi-col-sm-7">
            <div className="pi-section" style={{paddingLeft: '5px', paddingTop: '15px', paddingBottom: '15px', paddingRight: '0px'}}>
              <ColourText labelText="Frame" standardColour={awning.frameColourShortDescription}
                          customColour={awning.frameCustomColourDescription} col1={3} col2={9} />
              {awning.includePelmet &&
                <ColourText labelText={awning.pelmetName} standardColour={awning.pelmetColourShortDescription}
                                                   customColour={awning.pelmetCustomColourDescription} col1={3} col2={9}/>
              }
              { !order.isSparesOrder && <TextViewControl labelText={'No. in Order'} value={order.awnings.length} col1={3} col2={9} /> }
            </div>
          </div>
        </div>


        <div className="pi-row" style={{borderTop: '2px solid #2e343c', paddingTop: '10px', marginBottom: '-20px'}}>
          <div className="pi-col-sm-12" style={{paddingLeft: '20px'}}><h5>Awning #{awning.number} of {awningCount} {awning.fieldNumber > 0 ? `(Field #${awning.fieldNumber})` : ''} </h5></div>
        </div>

        <div className="pi-row" style={{marginBottom: '15px'}}>
          <div className="pi-col-sm-7">
            <div className="pi-section" style={{paddingLeft: '5px', paddingTop: '15px', paddingBottom: '15px', paddingRight: '0px'}}>

              <SummaryDivider labelText="Production details"/>

              <TextViewControl
                labelText="Size"
                value={`${awning.width.toString()} x ${awning.projection.toString()}`}
              />
              { (awning.sections > 1 || awning.defaultSections > 1) && <TextViewControl
                labelText="Joiner"
                value={awning.isAssembled ? 'Fully assembled' : `Dispatched in ${awning.sections} sections`}
              /> }
              { awning.productGroupId === PRODUCTGROUPID_CONSERVATORY && !awning.isMotorRequired ? <TextViewControl
                labelText="Drive mech."
                value={'Multi Field - no motor'}
              /> : <TextViewControl
                labelText="Drive mech."
                value={SpaceString([awning.sideOfOperation, awning.driveMechanismDescription, awning.motorDescription])}
              /> }
              
              {awning.motorSize === 'LT50' && awning.motorBrandId===2
                && <Aux>
                <TextViewControl
                    labelText={`Crown ring`}
                    value={"BK 4930 300 033 0 / MYOB 4650100000"}
                />
                <TextViewControl
                    labelText={`Drive adaptor`}
                    value={"BK 4930 300 091 0 / MYOB 4650200000"}
                />
                <TextViewControl
                    labelText={`Star bracket`}
                    value={"BK 4930 300 053 0 / MYOB 4650300000"}
                />
              </Aux>
              }
              {awning.motorSize === 'LT60' && awning.motorBrandId===2
              && <Aux>
                <TextViewControl
                    labelText={`Crown ring (L60)`}
                    value={"BK 4931 230 707 0 / MYOB 4650400000"}
                />
                <TextViewControl
                    labelText={`Drive adaptor (L60)`}
                    value={"BK 4931 300 086 0 / MYOB 4650500000"}
                />
                <TextViewControl
                    labelText={`Star bracket (L60)`}
                    value={"BK 4931 300 094 0 / MYOB 4650600000"}
                />
              </Aux>
              }

              {awning.frameFeature === FRAME_FEATURE_VARIOVOLANT && awning.motorBrandId === 1
              && <Aux>
                <TextViewControl
                    labelText={`VV Motor}`}
                    value={isRemoteFrameFeature(awning) ?  "SY 1001577 / MYOB 4650400000" : "SY 1001550 / MYOB 4320620000"}
                />
                <TextViewControl
                    labelText={`VV Crown`}
                    value={"SY 9017582 / MYOB 4500105000"}
                />
                <TextViewControl
                    labelText={`VV Wheel`}
                    value={"SY 9017581 / MYOB 4500109000"}
                />
                <TextViewControl
                    labelText={`VV Adaptor`}
                    value={"SY 9017583 / MYOB 4500112000"}
                />
              </Aux>
              }

              {awning.frameFeature === FRAME_FEATURE_VARIOVOLANT && awning.motorBrandId === 2
              && <Aux>
                <TextViewControl
                    labelText={`VV Motor`}
                    value={isRemoteFrameFeature(awning) ?  "BK 2009 130 123 0 / MYOB 4650910000" : "BK 2009 010 007 0 / MYOB 4320620000"}
                />
                <TextViewControl
                    labelText={`BK VV Crown 43`}
                    value={"BK 4930 300 409 0 / MYOB 4650930000"}
                />
                <TextViewControl
                    labelText={`BK VV Drive adaptor`}
                    value={"BK 4930 300 408 0 / MYOB 4650940000"}
                />
                <TextViewControl
                    labelText={`BK VV Motor plate`}
                    value={"BK 4930 300 405 0 / MYOB 4650950000"}
                />
              </Aux>
              }

              { awning.canSupplyOwnMotor && <TextViewControl
                  labelText="Motor supplied by"
                  value={awning.motorSupplierType === 2 ? order.organisationShortName : "Aluxor"}
              /> }
              
              { awning.productGroupId === PRODUCTGROUPID_CONSERVATORY && <TextViewControl
                labelText="Spring"
                value={SpaceString([awning.motorSpring, awning.sideOfOperation], ' - ')}
                visible={!IsNullOrUndefined(awning.motorId)}
              /> }

              <TextViewControl
                labelText="Cable"
                value={SpaceString([awning.automationCableDescription, awning.mocoTypeDescription], ' + ')}
              />

              { ((awning.frameFeature && awning.productGroupId !== PRODUCTGROUPID_ROOFING_SYSTEMS) || (awning.fieldConfiguration && awning.fieldConfiguration.numberOfFields > 1)) &&
              <TextViewControl
                labelText="Frame feature"
                value={SpaceString([awning.frameFeatureSideOfOperation, awning.frameFeatureDescription, awning.frameFeatureDriveMechanismDescription, awning.frameFeatureMotorDescription])}
              /> }

              { awning.fieldConfiguration && awning.fieldConfiguration.fieldConfigurationOption &&
              <TextViewControl
                  labelText="Field option"
                  value={awning.fieldConfiguration.fieldConfigurationOption.optionAsString}
              />}

              { (awning.frameFeature && awning.fabricSuppliedBy !== FABRIC_SUPPLIED_NONE)  &&
              <TextViewControl
                labelText={`${awning.frameFeatureShortName} drop`}
                value={awning.drop}
              /> }

              { awning.productGroupId === PRODUCTGROUPID_CONSERVATORY && (awning.productId === PRODUCTID_SD || awning.productId === PRODUCTID_SDM) &&
              <TextViewControl
                labelText="Tape length"
                value={(2 * awning.projection) +550}
              /> }
              { awning.productGroupId === PRODUCTGROUPID_CONSERVATORY && (awning.productId === PRODUCTID_SDC || awning.productId === PRODUCTID_SDCZ) &&
              <TextViewControl
                  labelText="Tape length"
                  value={(2 * awning.projection)}
              /> }

              {awning.intermediateRoller > 0 && <TextViewControl
                  labelText="Intermediate rollers"
                  value={`${awning.intermediateRoller}`}
              />}
              {awning.additionalImRollerCount > 0 && <TextViewControl
                  labelText="Additional IM rollers"
                  value={`${awning.additionalImRollerCount}`}
              />}

              {awning.includeLed === true && <TextViewControl
                  labelText="LED lighting"
                  value={"Yes"}
              />}

              <Aux visible={awning.productGroupId !== PRODUCTGROUPID_CONSERVATORY}>

                <SummaryDivider labelText="Arms, spacing, cradles"/>

                {awning.oversquare && <TextViewControl
                  labelText="Oversquare"
                  value={'Yes'}
                />}

                <TextViewControl
                  labelText="Arms"
                  value={awning.numberOfArms}
                />

                {awning.numberOfArms === 3 && awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM && <TextViewControl
                labelText="Middle arm side"
                value={awning.middleArmSideDescription}
              /> }

                <TextViewControl
                    labelText=" "
                    value={"For 3 arm awning checking position of middle arm and cradle. Co-ordinate with fabric maker to ensure fabric seam position aligns with cradle"}
                    visible={awning.numberOfArms === 3}
                />

                <TextViewControl
                  labelText="Outside spacing"
                  value={awning.outsideArmSpacing}
                />

                {awning.insideArmSpacing > 0 && <TextViewControl
                  labelText="Inside spacing"
                  value={awning.insideArmSpacing}
                />}

                { (isOl(awning) || isDs(awning)) &&
                <TextViewControl
                  labelText="Mounting"
                  value={`${awning.mountingBracketDescription}`}
                /> }

                {(awning.numberOfCradles + awning.additionalCradles) > 0 && <TextViewControl
                  labelText="Cradles"
                  value={`${awning.numberOfCradles + awning.additionalCradles} (align cradle with fabric seam)`}
                />}

                {(awning.productId === PRODUCTID_DS && awning.projection === 4000) && <TextViewControl
                    labelText="Levelling support"
                    value={`Fit levelling support to centre`}
                />}
                {(awning.productId === PRODUCTID_DS && awning.projection === 4000) && <TextViewControl
                    labelText=""
                    value={`Fit protective fabric to front rail`}
                />}
                
              </Aux>

              <AwningSADimensionSummary awning={awning} />              


              <SummaryDivider labelText="Fabric"/>

              <TextViewControl
                labelText="Fabric supply"
                value={SpaceString([awning.fabricSuppliedByDescription, awning.fabricSuppliedBy === FABRIC_SUPPLIED_BY_ALUXOR ?
                  order.fabricMakerShortName : null], ' - ')}
              />

              {awningRequiresFabric && <TextViewControl
                labelText="Fabric brand"
                value={awning.fabricBrandDescription}
              />}

              {awningRequiresFabric && <TextViewControl
                labelText="Fabric"
                value={awning.fabricDescription}
              />}

              {awningRequiresFabric && awning.fabricIsSupplierOutOfStock &&
              <div className="form-group">
                <label style={{color: '#ae2128'}}><i className={'fa fa-exclamation-triangle'}/> Fabric is currently out of stock at supplier{awning.fabricExpectedStockDate ? ', expected ' + moment(awning.fabricExpectedStockDate).format('MMM Do') : ', expected date unknown'}</label>
              </div>
              }

              {awning.fabricValance !== 0 && <TextViewControl
                labelText="Valance"
                value={SpaceString([awning.fabricValanceDescription, (awning.trimBiasColourDescription ? awning.trimBiasColourDescription : null)])}
              />}

              {awning.fabricSupports && <TextViewControl
                labelText="Fabric inserts"
                value={awning.fabricSupports}
              />}

              {awning.frameFeatureFabricBrandDescription && <TextViewControl
                labelText="Frame feature fabric"
                value={`${awning.frameFeatureFabricBrandDescription} - ${awning.frameFeatureFabricDescription}`}
              />}
              {awning.frameFeatureFabricBrandDescription && awning.frameFeatureFabricIsSupplierOutOfStock &&
              <div className="form-group">
                <label style={{color: '#ae2128'}}><i className={'fa fa-exclamation-triangle'}/> Fabric is currently out of stock at supplier{awning.frameFeatureFabricExpectedStockDate ? ', expected ' + moment(awning.frameFeatureFabricExpectedStockDate).format('MMM Do') : ', expected date unknown'}</label>
              </div>
              }

              <SummaryDivider labelText="Dispatch"/>

              <TextViewControl
                  labelText="Bracket type"
                  value={`${awning.mountingBracketDescription ? awning.mountingBracketDescription : 'Not Selected'} x ${bracketTotal}${awning.additionalMountingBrackets > 0 ? ' (incl. ' + awning.additionalMountingBrackets + ' additional)' : ''}`}
                  visible={bracketTotal > 0}
              />

              <TextViewControl
                  labelText="Dual Mount"
                  value={`${bracketTotalDualMount}`}
                  visible={!IsNullOrUndefined(bracketTotalDualMount)}
              />
              <TextViewControl
                  labelText="Single Mount"
                  value={`${bracketTotalSingleMount}`}
                  visible={(!IsNullOrUndefined(bracketTotalSingleMount) && bracketTotalSingleMount > 0)}
              />

              {awning.installationBracketDescription && awning.totalMountingBrackets > 0 && <TextViewControl
                labelText="Install bkt"
                value={`${awning.installationBracketDescription} x ${(awning.totalMountingBrackets).toString()}`}
              /> }
              {awning.installationBracketDescription && (IsNullOrUndefined(awning.totalMountingBrackets) || awning.totalMountingBrackets === 0) && <TextViewControl
                  labelText="Install bkt"
                  value={`${awning.installationBracketDescription}`}
              /> }
              {!IsNullOrUndefined(awning.installationBracketLength) && <TextViewControl
                  labelText="Post height"
                  value={`${awning.installationBracketLength}mm`}
              /> }


                {
                  ((awning.installationBracketOption && awning.installationBracketOption.postCount > 0) ||
                      (awning.fieldConfiguration && awning.fieldConfiguration.installationBracketOption && awning.fieldConfiguration.installationBracketOption.postCount > 0)) && <TextViewControl
                      labelText="Install option"
                      value={`Post set x ${(awning.installationBracketOption ? awning.installationBracketOption.postCount : awning.fieldConfiguration && awning.fieldConfiguration.installationBracketOption ? awning.fieldConfiguration.installationBracketOption.postCount : 0).toString()}`}
                  /> }

              {
                ((awning.installationBracketOption && awning.installationBracketOption.sideCount > 0) ||
                    (awning.fieldConfiguration && awning.fieldConfiguration.installationBracketOption && awning.fieldConfiguration.installationBracketOption.sideCount > 0)) && <TextViewControl
                    labelText="Install option"
                    value={`Side fix set x ${(awning.installationBracketOption ? awning.installationBracketOption.sideCount : awning.fieldConfiguration && awning.fieldConfiguration.installationBracketOption ? awning.fieldConfiguration.installationBracketOption.sideCount : 0).toString()}`}
                /> }

              {
                ((awning.installationBracketOption && awning.installationBracketOption.endCount > 0) ||
                    (awning.fieldConfiguration && awning.fieldConfiguration.installationBracketOption && awning.fieldConfiguration.installationBracketOption.endCount > 0)) && <TextViewControl
                    labelText="Install option"
                    value={`End fix set x ${(awning.installationBracketOption ? awning.installationBracketOption.endCount : awning.fieldConfiguration && awning.fieldConfiguration.installationBracketOption ? awning.fieldConfiguration.installationBracketOption.endCount : 0).toString()}`}
                /> }


              {awning.includePelmet &&
              <ColourText labelText={awning.pelmetName} standardColour={awning.pelmetColourShortDescription}
                          customColour={awning.pelmetCustomColourDescription}/>
              }

              {awning.includeWedges && bracketTotal > 0 && <TextViewControl
                  labelText="Wedges"
                  value={bracketTotal}
              />}
              
              {awning.includeStabiliserPoles && <TextViewControl
                labelText="Stabiliser poles"
                value='2'
              />}

              { awning.flashingType > 0 && awning.flashingType !== FLASHINGTYPE_WallSeal && <TextViewControl
                labelText={`Flashing`}
                value={`${awning.flashingCustomColourDescription == null ? awning.flashingColourShortDescription : awning.flashingCustomColourDescription}`}
              /> }

              { awning.flashingType === FLASHINGTYPE_WallSeal && <TextViewControl
                  labelText={`Wall seal`}
                  value={`${awning.flashingCustomColourDescription == null ? awning.flashingColourShortDescription : awning.flashingCustomColourDescription}`}
              /> }

              <TextViewControl
                labelText="Crank handle"
                value={awning.crankHandleDescription}
              />
              <TextViewControl
                labelText="Crank handle"
                value={awning.frameFeatureCrankHandleDescription}
              />

              { (awning.isInSetAndFirstAwning || !awning.isInSet) && awning.remoteDescription && awning.myobRemoteLookupDescription && <TextViewControl
                labelText="Remote control"
                value={`${awning.remoteDescription} ${awning.myobRemoteLookupDescription} - ${awning.myobRemoteLookupSupplierCode} x 1`}
              /> }

              { (awning.isInSetAndFirstAwning || !awning.isInSet) && awning.remoteDescription && awning.remote===REMOTE_MULTICHANNEL && awning.remoteParentAwningNumber > 0 && <TextViewControl
                  labelText="Remote control"
                  value={`${awning.remoteDescription}, join with Awning #${awning.remoteParentAwningNumber}`}
              /> }

              {awning.wallSwitch != null && <TextViewControl
                labelText="HW control"
                value={awning.wallSwitch}
              />}

              {awning.includeRainCover === true && <TextViewControl
                  labelText="Rain cover"
                  value={'x 2'}
              />}

              { (awning.isInSetAndFirstAwning || !awning.isInSet)
              && order.sensorCosts.filter(x => x.awningNumbers.indexOf(awning.number) >= 0).map(c => {
                return (<div className="pi-row" key={c.rowKey}>
                  <div className="pi-col-sm-4">{c.sensorTypeDescription}</div>
                  <div className="pi-col-sm-8">
                    {c.parts.map(p => <div key={p.id}>{p.description} - {p.supplierReference} x {p.quantity}</div>)}
                  </div>
                </div>);
              })}

              <TextViewControl
                labelText="Motion sensor colour"
                value={awning.motionSensorColour}
              />

              <TextViewControl
                labelText="Universal Receiver"
                value={'RTS - 1810624'}
                visible={(awning.frameFeature === FRAME_FEATURE_SA || awning.frameFeature === FRAME_FEATURE_SACP) && !IsNullOrUndefined(awning.motorId)}
              />

              <TextViewControl
                  labelText="Mounting brackets"
                  value={'Prepare for installation: tape fixing nuts to brackets'}
                  visible={awning.productId === PRODUCTID_TOGA}
              />

              {awning.notes.length > 0 && <SummaryDivider labelText="Awning Notes / Other"/> }
              {awning.notes.map(x => {
                return (
                    <Aux key={x.id}>
                      <div className="pi-row font-weight-bold">
                        <div className="pi-col-sm-4">{moment(x.orderDisplayDate).format(DATE_SHORT_FORMAT)}</div>
                        <div className="pi-col-sm-4">
                          {x.createdByPersonName}
                        </div>                        
                      </div>
                      <div className="pi-row">                        
                        <div className="pi-col-sm-12">
                          {x.description}
                        </div>
                      </div>
                    </Aux>
                );
              })}

            </div>
          </div>

          <div className="pi-col-sm-5" style={{paddingRight: '10px'}}>
            <div className="pi-section" style={{paddingLeft: '5px', paddingTop: '15px', paddingBottom: '15px', paddingRight: '0px'}}>
              <div style={{border: '2px solid #2e343c', margin: '-10px', padding: '10px', marginBottom: '25px', paddingTop: '0px'}}>
                
                <AwningCuttingDeductionsControl awning={awning} 
                                                cuttingDeductions={awning.cuttingDimension} 
                                                awningRequiresFabric={awningRequiresFabric}
                                                headerText={"Cutting deductions"} />

              </div>
              

              <SummaryDivider labelText="Delays"/>
              { awning.fabricSuppliedBy !== FABRIC_SUPPLIED_NONE && <div className="pi-row">
                <div className="pi-col-sm-6">Waiting fabric {awning.fabricSuppliedBy === FABRIC_SUPPLIED_CUSTOMER ? '(customer)' :
                  order.fabricMakerShortName ? `(${order.fabricMakerShortName})` : ''}</div>
                <div className="pi-col-sm-3"><div className={classes.checkOffBox}>&nbsp;</div></div>
                <div className="pi-col-sm-3">Date:</div>
              </div> }
              { !isManual(awning) && <div className="pi-row">
                <div className="pi-col-sm-6">Waiting motor</div>
                <div className="pi-col-sm-3"><div className={classes.checkOffBox}>&nbsp;</div></div>
                <div className="pi-col-sm-3">Date:</div>
              </div> }
              { (awning.frameCustomColourId || awning.pelmetCustomColourId || awning.flashingCustomColourId)
                  && order.powderCoaterShortName && <div className="pi-row">
                <div className="pi-col-sm-6">Waiting PC ({order.powderCoaterShortName})</div>
                <div className="pi-col-sm-3"><div className={classes.checkOffBox}>&nbsp;</div></div>
                <div className="pi-col-sm-3">Date:</div>
              </div> }
              <div className="pi-row">
                <div className="pi-col-sm-6">Waiting components</div>
                <div className="pi-col-sm-3"><div className={classes.checkOffBox}>&nbsp;</div></div>
                <div className="pi-col-sm-3">Date:</div>
              </div>

              <SummaryDivider labelText="Checks"/>
              <div className="pi-row">
                <div className="pi-col-sm-6">Assembled</div>
                <div className="pi-col-sm-3">Inital:</div>
                <div className="pi-col-sm-3">Date:</div>
              </div>
              <div className="pi-row">
                <div className="pi-col-sm-6">Fabric quality</div>
                <div className="pi-col-sm-3">Inital:</div>
                <div className="pi-col-sm-3">Date:</div>
              </div>

              <div className="pi-row">
                <div className="pi-col-sm-6">Q+S checked</div>
                <div className="pi-col-sm-3">Inital:</div>
                <div className="pi-col-sm-3">Date:</div>
              </div>

              <div className="pi-row">
                <div className="pi-col-sm-6">Dispatched</div>
                <div className="pi-col-sm-3">Inital:</div>
                <div className="pi-col-sm-3">Date:</div>
              </div>
              <div className="pi-row">
                <div className="pi-col-sm-6">Customer notified</div>
                <div className="pi-col-sm-3"><div className={classes.checkOffBox}>&nbsp;</div>&nbsp;E&nbsp;&nbsp;<div className={classes.checkOffBox}>&nbsp;</div>&nbsp;T</div>
                <div className="pi-col-sm-3">Date:</div>
              </div>
              <div className="pi-row">
                <div className="pi-col-sm-6">Invoice check</div>
                <div className="pi-col-sm-6"><div className={classes.checkOffBox}>&nbsp;</div>&nbsp;P&nbsp;&nbsp;<div className={classes.checkOffBox}>&nbsp;</div>&nbsp;A</div>                
              </div>

              <div className="pi-row" style={{marginTop: '17px', borderTop: '2px solid', paddingTop: '10px'}}>
                <div className="pi-col-sm-12" style={{fontWeight: 'bold'}}>{order.dispatchTypeDescription}</div>
              </div>

              <div className="pi-row" style={{paddingBottom: '10px',}}>
                <div className="pi-col-sm-12">
                  { order.dispatchTypeId != DISPATCH_COLLECT && <div className={'pi-row'} style={{marginLeft: '0px'}}>
                    <TextViewControl labelText={'Courier'} value={order.courierName ? order.courierName : order.courierOther} />
                    <TextViewControl labelText={'Con. note'} value={order.courierConsignmentReference} />
                    {!isNullEmptyOrWhitespace(order.courierNoBoxes) && <TextViewControl labelText={'No. boxes'} value={order.courierNoBoxes} /> }
                    <TextViewControl labelText={'Dispatch date'} value={order.courierDispatchDate ? moment(order.courierDispatchDate).format(DATE_SHORT_FORMAT) : ''} />
                    <TextViewControl labelText={'Dispatched by'} value={''} />
                  </div> }

                  { order.dispatchTypeId == DISPATCH_COLLECT &&
                  <div className={'pi-row'} style={{marginLeft: '0px'}}>

                    <div className="pi-row" style={{height: '40px'}}>
                      <div className="pi-col-sm-4">Collected by</div>
                      <div className="pi-col-sm-7" style={{borderBottom: '1px solid'}} >&nbsp;</div>
                    </div>
                    <div className="pi-row" style={{height: '40px'}}>
                      <div className="pi-col-sm-4">Date</div>
                      <div className="pi-col-sm-7" style={{borderBottom: '1px solid'}} >&nbsp;</div>
                    </div>
                    <div className="pi-row" style={{height: '40px'}}>
                      <div className="pi-col-sm-4">Dispatched by</div>
                      <div className="pi-col-sm-7" style={{borderBottom: '1px solid'}} >&nbsp;</div>
                    </div>

                  </div> }

                </div>
              </div>

              <div className="pi-row" style={{marginTop: '20px'}}>
                <div className="pi-col-sm-4">Invoice<br />PF-{order.id}</div>
                <div className="pi-col-sm-8" style={{border: '2px solid #2e343c', height: '3em'}}>&nbsp;</div>
              </div>
            </div>
          </div>

        </div>

  {awning.number < order.awnings.length && <div className={'html2pdf__page-break'}></div> }

</div>) : null;
  }
}

AwningProductionSheet.propTypes = {
  awning: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
};

export default injectSheet(styles)(AwningProductionSheet);