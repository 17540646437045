import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { apiRequest, apiDelete } from "../../utils/request";
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import SelectControl from '../common/SelectControl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toastr } from 'react-redux-toastr';
import {
  ORDER_STATUS_QUOTE,
  ORDER_STATUS_ORDER_REQUESTED,
  ORDER_STATUS_IN_PRODUCTION,
  ORDER_STATUS_ORDER_DELAYED,
  ORDER_STATUS_ORDER_PRODUCTION_COMPLETE,
  ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED,
  ORDER_STATUS_ORDER_PRODUCTDISPATCHED,
  ORDER_STATUS_ORDER_CANCELLED,
  orders_list_select_edit_order_quote,
  orders_list_select_edit_order_amendment_requested,
  orders_list_select_view_order_all,
  orders_list_select_delete_order_quote,
  orders_list_list_all_organisations_all,
  DATE_SHORT_FORMAT,
  orders_list_select_edit_spare_order_amendment_requested,
  orders_list_select_edit_spare_order_quote,
  orders_list_select_delete_spare_order_quote,
  obsfucate,
  ROLE_Administrator,
  PORTALSERVICE_AwningQuote,
  PORTALSERVICE_SpareQuote,
  isNullEmptyOrWhitespace,
  IsNullOrUndefined,
  OrderStatuses,
  PRODUCTID_ECO,
  PRODUCTID_S1,
  PRODUCTID_S3,
  PRODUCTID_TOGA,
  PRODUCTID_DS,
  PRODUCTID_SD,
  PRODUCTID_SDM,
  PRODUCTID_SDC,
  PRODUCTID_SDCZ,
  PRODUCTID_ST,
  PRODUCTID_OLYMPUS, Aux
} from '../../constants';
import moment from 'moment';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import {loadOrders, updateFilter, updateNonPostFilter} from '../../actions/orderActions';
import initialState from '../../reducers/initialState';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import TextBoxControl from "../common/TextBoxControl";
import {sumBy} from "lodash/math";
import CheckBoxControl from "../common/CheckBoxControl";
import {max} from "lodash";

const projections = [
  { label: '1500', value: 1500 },
  { label: '2000', value: 2000 },
  { label: '2500', value: 2500 },
  { label: '3000', value: 3000 },
  { label: '3500', value: 3500 },
  { label: '4000', value: 4000 },
];

export const FilterWidths = [
  { label: 'Up to 4000', value: { minWidth: 0, maxWidth: 4000 } },
  { label: '4001 - 5000', value: { minWidth: 4001, maxWidth: 5000 } },
  { label: '5001 - 6000', value: { minWidth: 5001, maxWidth: 6000 } },
  { label: '6001 - 7000', value: { minWidth: 6001, maxWidth: 7000 } },
  { label: '> 7001', value: { minWidth: 7001, maxWidth: 1000000 } },
];

export const FilterProjections = [
  { label: 'Up to 1500', value: { minWidth: 0, maxWidth: 1500 } },
  { label: '1501 - 2000', value: { minWidth: 1501, maxWidth: 2000 } },
  { label: '2001 - 2500', value: { minWidth: 2001, maxWidth: 2500 } },
  { label: '2501 - 3000', value: { minWidth: 2501, maxWidth: 3000 } },
  { label: '3001 - 3500', value: { minWidth: 3001, maxWidth: 3500 } },
  { label: '3501 - 4000', value: { minWidth: 3501, maxWidth: 4000 } },
  { label: '4001 - 5000', value: { minWidth: 4001, maxWidth: 5000 } },
  { label: '5001 - 6500', value: { minWidth: 5001, maxWidth: 6500 } },  
];

const outstandings = [
  { label: 'PC', value: 1 },
  { label: 'Fabric', value: 2 },
];

const maxOrderCountOptions = [
  { label: '100 orders', value: '100' },
  { label: '250 orders', value: '250' },
  { label: '500 orders', value: '500' },
  { label: '750 orders', value: '750' },
  { label: '1000 orders', value: '1000' },
  { label: '2000 orders', value: '2000' },
  { label: 'All orders', value: '32768' },
]


class OrdersPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      columns: [],
      datePickerOpen: false,
    };
    this.handleFilterFieldChange = this.handleFilterFieldChange.bind(this);
    this.toggleDatePicker = this.toggleDatePicker.bind(this);
    this.updateFilterField = this.updateFilterField.bind(this);
    this.applySortFilter = this.applySortFilter.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.handleArrayFieldFilterUpdate = this.handleArrayFieldFilterUpdate.bind(this);
    this.initialFilter = this.initialFilter.bind(this);
    this.isDirtyFilter = this.isDirtyFilter.bind(this);
    this.handleProductFilterLink = this.handleProductFilterLink.bind(this);
  }

  handleDeleteOrder(e, orderId) {
    e.preventDefault();
    //eslint-disable-next-line
    if (!confirm(`Are you sure you want to delete Order #${orderId}`)) {
      return;
    }

    apiDelete(`api/orders/${orderId}`).then(x => {
      //todo: Add toastr
      this.loadOrders();
    });
  }

  loadOrders(orgs = null) {
    this.props.dispatch(loadOrders(this.props.orders.filter));
  }

  setColumns() {
    let { user, orders } = this.props;
    let canViewAll = user && user.hasAccessTag(orders_list_list_all_organisations_all);
    this.setState({
      columns: [{
        Header: 'Status',
        accessor: 'orderStatusDescription',
        sortMethod: (a, b) => {
          let aVal = OrderStatuses.find(x => x.label == a);
          let bVal = OrderStatuses.find(x => x.label == b);

          return aVal.value > bVal.value ? 1 : -1;
        },
        maxWidth: 125,
      }, {
        id: 'sequenceNumber',
        Header: 'PN',
        accessor: d => d.sequenceNumber,
        maxWidth: 80,
        show: canViewAll,
      }, {
        id: 'invoiceNumber',
        Header: 'Inv. No.',
        accessor: d => d.invoiceNumber,
        maxWidth: 80,
        show: orders.nonPostFilter.showInvoiceNumber,
      }, {
        Header: 'Org.',
        accessor: 'organisationShortName',
        maxWidth: 90,
        show: canViewAll,
      }, {
        Header: 'Customer Ref',
        accessor: 'customerReference',
        minWidth: canViewAll ? 115 : 425,
      }, {
        Header: 'No.',
        accessor: 'awningCount',
        maxWidth: 55,
        show: canViewAll,
      }, {
        Header: 'Fabric',
        accessor: 'fabricMakerShortName',
        maxWidth: 60,
        show: canViewAll,
      }, {
        Header: `PC'er`,
        accessor: 'powderCoaterShortName',
        maxWidth: 60,
        show: canViewAll,
      }, {
        Header: `Product`,
        accessor: 'awningProducts',
        maxWidth: 85,
      }, {
        id: 'createdDate',
        Header: `Date`,
        accessor: d => moment(d.orderDisplayDate).format(DATE_SHORT_FORMAT),
        sortMethod: (a, b) => {
          let aVal = moment(a, DATE_SHORT_FORMAT);
          let bVal = moment(b, DATE_SHORT_FORMAT);

          return aVal.isBefore(bVal) ? -1 : 1;
        },
        maxWidth: 120,
      },
       {
        id: 'estimatedDeliveryDate',
        Header: `Est. Completion`,
        accessor: d => IsNullOrUndefined(d.estimatedDeliveryDate) ? 'N/A' : moment(d.estimatedDeliveryDate).format(DATE_SHORT_FORMAT),
        sortMethod: (a, b) => {

          if(a!=="N/A" && b!=="N/A"){
            let aVal = moment(a, DATE_SHORT_FORMAT);
            let bVal = moment(b, DATE_SHORT_FORMAT);

            return aVal.isBefore(bVal) ? -1 : 1;  
          }
          
          if(b==="N/A"){
            return -1;
          }
        },
        maxWidth: 120,
        show: true,
      },
      {
        id: 'actions',
        Header: '',
        accessor: c => {
          let { user } = this.props;
          let showEdit = (c.orderStatus == ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED && (user.hasAccessTag(orders_list_select_edit_order_amendment_requested) || user.hasAccessTag(orders_list_select_edit_spare_order_amendment_requested)))
            || (c.orderStatus == ORDER_STATUS_QUOTE && (user.hasAccessTag(orders_list_select_edit_order_quote) || user.hasAccessTag(orders_list_select_edit_spare_order_quote)));
          let showView = user.hasAccessTag(orders_list_select_view_order_all);
          let showDelete = user.isInRole(ROLE_Administrator) || (c.orderStatus == ORDER_STATUS_QUOTE && (user.hasAccessTag(orders_list_select_delete_order_quote) || user.hasAccessTag(orders_list_select_delete_spare_order_quote)));
          return (
            <span>
              {showEdit && <Link
                to={`/${c.isSparesOrder ? 'spares' : 'orders'}/edit/${obsfucate(c.id)}`}
                style={{ marginRight: '20px' }}
              ><i className="fa fa-edit fa-lg" /></Link>}
              {showView && <Link
                to={`/orders/view/${obsfucate(c.id)}`}
                style={{ marginRight: '20px' }}
              ><i className="fa fa-binoculars fa-lg" /></Link>}
              {showDelete && <a
                href="#"
                style={{ marginRight: '20px' }}
                onClick={(e) => this.handleDeleteOrder(e, c.id)}><i className="fa fa-times fa-lg" /></a>}
            </span>
          );
        },
        width: 125,
      }, {
        Header: `Colour`,
        accessor: 'distinctCustomColoursString',
        show: false
      }, {
        Header: `Width`,
        accessor: 'maxWidth',
        show: false
      }, {
        Header: `Projection`,
        accessor: 'maxProjection',
        show: false
      }, {
        Header: `Motors`,
        accessor: 'motors',
        show: false
      }]
    });
  }

  toggleDatePicker() {
    this.setState({ datePickerOpen: !this.state.datePickerOpen });
  }

  handleFilterFieldChange(val, field) {
    let filter = { ...this.props.orders.filter };

    filter[field] = val;

    this.props.dispatch(updateFilter(filter));
  }

  updateFilterField(e) {
    this.handleFilterFieldChange(e.target.value, e.target.name);
  }

  handleArrayFieldFilterUpdate(v, field){
    let updatedVal = isNullEmptyOrWhitespace(v) ? [] : v.split(',').map(x => parseInt(x));
    this.handleFilterFieldChange(updatedVal, field);
  }

  applySortFilter(e, field) {
    let newFilter = e.target.checked && field !== '' ? {
      ...this.props.orders.filter,
      sorted: [{ id: field, desc: this.props.orders.filter.sortDirectionDesc }]
    } : {
        ...this.props.orders.filter,
        sorted: []
      };
    this.props.dispatch(updateNonPostFilter({...this.props.orders.nonPostFilter, sorted: newFilter.sorted}));
  }

  isDirtyFilter(){
    let init = JSON.stringify(this.initialFilter());
    let current = JSON.stringify(this.props.orders.filter);
    return init !== current;
  }

  initialFilter() {
    if (this.props.user.hasAccessTag(orders_list_list_all_organisations_all)) {
      return {
        filter: {
          ...initialState.orders.filter,
          orderStatuses: [ORDER_STATUS_IN_PRODUCTION, ORDER_STATUS_ORDER_REQUESTED],
        },
        nonPostFilter: {
          ...initialState.orders.nonPostFilter,
        }
      };
    } else {
      return {
        filter: {
          ...initialState.orders.filter,
          orderStatuses: [ORDER_STATUS_IN_PRODUCTION, ORDER_STATUS_ORDER_REQUESTED],
        },
        nonPostFilter: {
          ...initialState.orders.nonPostFilter,
          sorted: [{id: "orderStatusDescription", desc: false}, {id: "createdDate", desc: true}],
        }
      };
    }
  }

  resetFilters(){
    let {filter, nonPostFilter} = this.initialFilter();
    this.props.dispatch(loadOrders(filter, nonPostFilter));
  }

  componentWillMount() {
    this.setColumns();
  }

  handleProductFilterLink(e, type) {
    e.preventDefault();

    let {orders, lookups} = this.props;    
    let {filter} = orders;
    
    let newProducts = [];

    if (type === 'all') {
      newProducts = [...lookups.productsForSearch.filter(x => x.value !== -1).map(x => x.value)];
    } else if (type === 'FAA') {
      newProducts = [PRODUCTID_ECO, PRODUCTID_S1, PRODUCTID_S3, PRODUCTID_TOGA, PRODUCTID_OLYMPUS, PRODUCTID_DS];
    } else if (type === 'SD') {
      newProducts = [PRODUCTID_SD, PRODUCTID_SDM, PRODUCTID_SDC, PRODUCTID_SDCZ];
    } else if (type === 'ST') {
      newProducts = [PRODUCTID_ST];
    } else if (type === 'SPR') {
      newProducts = [-1];
    }

    let newFilter= {
      ...filter,
      productIds: newProducts,
    };
    
    this.props.dispatch(updateFilter(newFilter));
  }

  componentDidUpdate(prevProps) {
    if(JSON.stringify(this.props.orders.nonPostFilter) !== JSON.stringify(prevProps.orders.nonPostFilter)) {      
      this.setColumns();
    }
  }

  render() {    
    let { columns } = this.state;
    let { organisations, orders, lookups } = this.props;
    let { filter, items, nonPostFilter, loaded } = orders;
    let {createdDateFrom, createdDateTo} = filter;
    let canAwningQuote = this.props.user && this.props.user.portalServices.filter(x => x.id === PORTALSERVICE_AwningQuote).length === 1;
    let canSpareQuote = this.props.user && this.props.user.portalServices.filter(x => x.id === PORTALSERVICE_SpareQuote).length === 1;
    let isDirty = this.isDirtyFilter();
    let totalAwningCount = sumBy(items, x => x.awningCount);

    return (
      <div className="pi-section">

        <div className="pi-row">
          <div className={'pi-col-sm-3'}>
            <h4>View Quotes/Orders</h4>
          </div>
          <div className={'pi-col-sm-2'} style={{marginTop: '4px'}}>
            {this.props.user.hasAccessTag(orders_list_list_all_organisations_all) && <a href={''} onClick={(e) => {
              e.preventDefault();
              this.props.dispatch(updateNonPostFilter({
                ...nonPostFilter,
                advancedOpen: !nonPostFilter.advancedOpen
              }));
            }}>{ nonPostFilter.advancedOpen ? 'Hide' : 'Show' } Advanced Filters</a> }
          </div>
          <div className={'pi-col-sm-7'} style={{ textAlign: 'right' }}>
            { orders.loaded && <button
              onClick={(e) => {
                e.preventDefault();
                this.props.dispatch(loadOrders(filter));
              }}
              className="btn pi-btn pi-uppercase"
              style={{marginRight: '5px'}}
            >
              <i className="fa fa-retweet"></i>&nbsp;Refresh Results
            </button> }
            {canSpareQuote &&
              <button
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push('/spares/create');
                }}
                className="btn pi-btn-base pi-uppercase">
                <i className="fa fa-plus"></i>&nbsp;Spare Quote/Order
            </button>}
            &nbsp;
            {canAwningQuote && <button
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push('/orders/create');
              }}
              className="btn pi-btn-base pi-uppercase">
              <i className="fa fa-plus"></i>&nbsp;Awning Quote/Order
            </button>}
          </div>
        </div>

        <div className="pi-row">
          <div className={'pi-col-sm-12'}>&nbsp;</div>
        </div>
        {this.props.user.hasAccessTag(orders_list_list_all_organisations_all) ?
            (<div className="pi-row">
            <div className={'col-sm-12'}>
              <form>
                <div className="form-group row">
                  <div className="col-sm-4">
                    <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      multi={true}
                      labelText="Status"
                      name="status"
                      options={OrderStatuses}
                      onChange={(val) => this.handleArrayFieldFilterUpdate(val, 'orderStatuses')}
                      simpleValue={true}
                      value={filter.orderStatuses}
                      placeholder={'Order status'}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Select
                      validationMessages={this.state.validationMessages}
                      labelText="Customers"
                      name="organisationId"
                      options={organisations.items}
                      multi={true}
                      simpleValue={true}
                      onChange={(val) => this.handleArrayFieldFilterUpdate(val, 'organisationIds')}
                      value={filter.organisationIds}
                      valueKey={'id'}
                      labelKey={'description'}
                      placeholder="Organisations"
                    />
                  </div>
                  <div className={'col-sm-2'}>
                    <input type={'text'}
                           className={'form-control'}
                           value={filter.customerRef}
                           onChange={this.updateFilterField}
                           name={'customerRef'}
                           placeholder={'Customer Ref'}/>                    
                  </div>

                  <div className={'col-sm-2'}>
                    <input type={'text'}
                           className={'form-control'}
                           value={filter.orderId}
                           onChange={this.updateFilterField}
                           name={'orderId'}
                           placeholder={'PF Order Id'} />
                  </div>

                  <div className={'col-sm-2'}>
                    <input type={'text'}
                           className={'form-control'}
                           value={filter.sequenceNumber}
                           onChange={this.updateFilterField}
                           name={'sequenceNumber'}
                           placeholder={'Prod. No.'} />
                  </div>
                  
                </div>

                <div className="form-group row">
                  <div className="col-sm-3">
                    <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      validationMessages={this.state.validationMessages}
                      name="productIds"
                      options={lookups.productsForSearch}
                      multi={true}
                      simpleValue={true}
                      onChange={(val) => this.handleArrayFieldFilterUpdate(val, 'productIds')}
                      value={filter.productIds}
                      placeholder="Products"
                    />
                    <div className={"mt-2"}>
                      <a href={''} onClick={e => this.handleProductFilterLink(e, 'all')}>All Products</a>
                      <a href={''} className={"ml-2"} onClick={e => this.handleProductFilterLink(e, 'FAA')}>FAA</a>
                      <a href={''} className={"ml-2"} onClick={e => this.handleProductFilterLink(e, 'SD')}>SD</a>
                      <a href={''} className={"ml-2"} onClick={e => this.handleProductFilterLink(e, 'ST')}>ST</a>
                      <a href={''} className={"ml-2"} onClick={e => this.handleProductFilterLink(e, 'SPR')}>SPR</a>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      validationMessages={this.state.validationMessages}
                      name="fabricMakerId"
                      options={lookups.fabricMakers}
                      multi={true}
                      simpleValue={true}
                      onChange={(val) => this.handleArrayFieldFilterUpdate(val, 'fabricMakerId')}
                      value={filter.fabricMakerId}
                      placeholder="Fabric Makers"
                    />
                  </div>
                  <div className="col-sm-3">
                    <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      validationMessages={this.state.validationMessages}
                      name="powderCoaterId"
                      options={lookups.powderCoaters}
                      multi={true}
                      simpleValue={true}
                      onChange={(val) => this.handleArrayFieldFilterUpdate(val, 'powderCoaterId')}
                      value={filter.powderCoaterId}
                      placeholder="Powder Coaters"
                    />
                    <br />
                    <Select
                        clearable={true}
                        deleteRemoves={true}
                        backspaceRemoves={true}
                        multi={true}
                        labelText="Drive Mechanism"
                        name="driveMechanism"
                        simpleValue={true}
                        options={lookups.driveMechanisms}
                        onChange={(v) => this.handleArrayFieldFilterUpdate(v, 'driveMechanismId')}
                        value={filter.driveMechanismId}
                        placeholder={'Drive Mechanism'}
                    />
                  </div>
                  <div className="col-sm-3">
                    <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      multi={true}
                      labelText="Colour"
                      name="colours"
                      simpleValue={true}
                      options={lookups.coloursForSearch}
                      onChange={(v) => this.handleArrayFieldFilterUpdate(v, 'colourId')}
                      value={filter.colourId}
                      placeholder={'Colours'}
                    />
                    <br />
                    <Select
                        clearable={true}
                        deleteRemoves={true}
                        backspaceRemoves={true}
                        multi={true}
                        labelText="Std. Col."
                        name="standardColours"
                        simpleValue={true}
                        options={lookups.standardColoursForSearch}
                        onChange={(v) => this.handleArrayFieldFilterUpdate(v, 'standardColourId')}
                        value={filter.standardColourId}
                        placeholder={'Standard Colours'}
                    />
                  </div>
                </div>                

                { nonPostFilter.advancedOpen && <div className="form-group row">
                  <div className={'col-sm-2'}>
                    <input type={'number'}
                           className={'form-control'}
                           value={filter.orderId}
                           onChange={this.updateFilterField}
                           name={'orderId'}
                           placeholder={'Order Id'} />
                  </div>
                  <div className="col-sm-2">
                    <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      multi={false}
                      labelText="Outstanding"
                      name="outstanding"
                      options={outstandings}
                      onChange={(v) => {
                        if (v == 1) {
                          this.props.dispatch(updateFilter({...filter, outstandingPc: true, outstandingFabric: null}));
                        } else if (v == 2) {
                          this.props.dispatch(updateFilter({...filter, outstandingPc: null, outstandingFabric: true}));
                        } else {
                          this.props.dispatch(updateFilter({...filter, outstandingPc: null, outstandingFabric: null}));
                        }
                      }}
                      simpleValue={true}
                      value={filter.outstandingPc ? 1 : filter.outstandingFabric ? 2 : null}
                      placeholder={'Outstanding'}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      multi={false}
                      labelText="Width"
                      name="width"
                      options={FilterWidths}
                      onChange={(v) => {
                        this.handleFilterFieldChange(v, 'width');                        
                      }}
                      simpleValue={false}
                      value={filter.width}
                      placeholder={'Width'}
                    />

                  </div>
                  <div className="col-sm-3">
                    <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      multi={false}
                      labelText="Projection"
                      name="projection"
                      options={projections}
                      onChange={(v) => {
                        this.handleFilterFieldChange(v, 'projection');
                      }}
                      simpleValue={true}
                      value={filter.projection}
                      placeholder={'Projection'}
                    />
                  </div>
                  <div className="col-sm-3">
                    <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      multi={false}
                      labelText="Motor"
                      name="motors"
                      simpleValue={true}
                      options={lookups.motorBrandsForSearch}
                      onChange={(v) => this.handleFilterFieldChange(v, 'motorBrandId')}
                      value={filter.motorBrandId}
                      placeholder={'Motor brand'}
                    />
                  </div>
                </div> }

                { nonPostFilter.advancedOpen && <Aux>
                  <div className="form-group row">
                  <div className={'col-sm-2'}>
                    <input type={'text'}
                           className={'form-control'}
                           value={filter.invoiceNumber}
                           onChange={this.updateFilterField}
                           name={'invoiceNumber'}
                           placeholder={'Invoice Number'}/>
                  </div>
                  <div className={'col-2'}>
                    <CheckBoxControl labelText={'Show Inv. No.'} value={nonPostFilter.showInvoiceNumber}
                                     onChange={(val) => this.props.dispatch(updateNonPostFilter({
                                         ...nonPostFilter,
                                         showInvoiceNumber: val.target.checked
                                       }))}
                                     name={'showInvoiceNumber'}/>
                  </div>
                  <div className={'col-sm-2'}>
                    <DatePicker
                        selected={createdDateFrom}
                        selectsStart
                        startDate={createdDateFrom}
                        endDate={createdDateTo}
                        onChange={a => this.handleFilterFieldChange(a, 'createdDateFrom')}
                        className={'form-control'}
                        dateFormat={DATE_SHORT_FORMAT}
                        placeholderText={'Date From'}
                        dropdownMode={"scroll"}
                    />
                  </div>
                  <div className={'col-sm-2'}>
                    <DatePicker
                        selected={createdDateTo}
                        selectsEnd
                        startDate={createdDateFrom}
                        endDate={createdDateTo}
                        onChange={a => this.handleFilterFieldChange(a, 'createdDateTo')}
                        className={'form-control'}
                        dateFormat={DATE_SHORT_FORMAT}
                        placeholderText={'Date To'}
                        dropdownMode={"scroll"}
                    />
                  </div>
                </div>
                  <div className="form-group row">
                    <div className="col-sm-8">
                      <label style={{marginTop: '5px'}}><b>Sort: </b></label>

                      <label style={{marginRight: '15px'}}>
                        <input type="radio"
                               name={'sortByRadio'}
                               checked={nonPostFilter.sorted
                                   .findIndex(x =>
                                       x.id === 'distinctCustomColoursString'
                                       || x.id === 'maxWidth'
                                       || x.id === 'maxProjection'
                                       || x.id === 'maxProjection'
                                       || x.id === 'motors'
                                   ) === -1}
                               onChange={(e) => this.applySortFilter(e, '')}
                        />&nbsp;Grid
                      </label>
                      <label style={{marginRight: '15px'}}>
                        <input type="radio"
                               name={'sortByRadio'}
                               checked={nonPostFilter.sorted.findIndex(x => x.id === 'distinctCustomColoursString') >= 0}
                               onChange={(e) => this.applySortFilter(e, 'distinctCustomColoursString')}
                        />&nbsp;Custom Colour
                      </label>
                      <label style={{marginRight: '15px'}}>
                        <input type="radio"
                               name={'sortByRadio'}
                               checked={nonPostFilter.sorted.findIndex(x => x.id === 'maxWidth') >= 0}
                               onChange={(e) => this.applySortFilter(e, 'maxWidth')}
                        />&nbsp;Width
                      </label>
                      <label style={{marginRight: '15px'}}>
                        <input type="radio"
                               name={'sortByRadio'}
                               checked={nonPostFilter.sorted.findIndex(x => x.id === 'maxProjection') >= 0}
                               onChange={(e) => this.applySortFilter(e, 'maxProjection')}
                        />&nbsp;Projection
                      </label>
                      <label>
                        <input type="radio"
                               name={'sortByRadio'}
                               checked={nonPostFilter.sorted.findIndex(x => x.id === 'motors') >= 0}
                               onChange={(e) => this.applySortFilter(e, 'motors')}
                        />&nbsp;Motor
                      </label>
                      <select value={nonPostFilter.sortDirectionDesc}
                              style={{width: '80px', marginLeft: '10px'}}
                              className={'form-control'}
                              onChange={(e) => {
                                this.props.dispatch(updateNonPostFilter({
                                  ...nonPostFilter,
                                  sortDirectionDesc: e.target.value == 'true',
                                  sorted: nonPostFilter.sorted.map(y => {
                                    return {...y, desc: e.target.value == 'true'}
                                  }),
                                }));
                              }}>
                        <option value={false}>asc</option>
                        <option value={true}>desc</option>
                      </select>
                    </div>
                    <div className="col-sm-2">
                      <label style={{marginTop: '5px'}}><b>Limit to: </b></label>
                    </div>
                    <div className="col-sm-2">
                      <Select
                          clearable={false}
                          multi={false}
                          labelText="Order count limit"
                          name="maxOrderCount"
                          options={maxOrderCountOptions}
                          onChange={(val) => this.handleFilterFieldChange(val, "maxOrderCount")}
                          simpleValue={true}
                          value={filter.maxOrderCount}
                          placeholder={'Order count limit'}
                      />
                    </div>
                    </div>
                </Aux>
                }
              </form>
            </div>
            </div>) :
            (<div className="pi-row">
              <div className={'col-sm-12'}>
                <form>
                  <div className="form-group row">
                    <div className="col-sm-4">
                      <Select
                          clearable={true}
                          deleteRemoves={true}
                          backspaceRemoves={true}
                          multi={true}
                          labelText="Status"
                          name="status"
                          options={OrderStatuses}
                          onChange={(val) => this.handleArrayFieldFilterUpdate(val, 'orderStatuses')}
                          simpleValue={true}
                          value={filter.orderStatuses}
                          placeholder={'Order status'}
                      />
                    </div>
                    <div className="col-sm-2">
                      <input type={'text'}
                             className={'form-control'}
                             value={filter.customerRef}
                             onChange={this.updateFilterField}
                             name={'customerRef'}
                             placeholder={'Customer Ref'} />
                    </div>
                    <div className={'col-sm-3'}>
                      <DatePicker
                          selected={createdDateFrom}
                          selectsStart
                          startDate={createdDateFrom}
                          endDate={createdDateTo}
                          onChange={a => this.handleFilterFieldChange(a, 'createdDateFrom')}
                          className={'form-control'}
                          dateFormat={DATE_SHORT_FORMAT}
                          placeholderText={'Date From'}
                          dropdownMode={"scroll"}
                      />
                    </div>
                    <div className={'col-sm-3'}>
                      <DatePicker
                          selected={createdDateTo}
                          selectsEnd
                          startDate={createdDateFrom}
                          endDate={createdDateTo}
                          onChange={a => this.handleFilterFieldChange(a, 'createdDateTo')}
                          className={'form-control'}
                          dateFormat={DATE_SHORT_FORMAT}
                          placeholderText={'Date To'}
                          dropdownMode={"scroll"}
                      />
                    </div>  
                  </div>
                </form>
              </div>
            </div>)
        }

        <div className="pi-row" style={{ marginBottom: '20px' }}>
          { (isDirty || !orders.loaded) && <div className="pi-col-sm-4">
            { !orders.loaded &&  <button
              onClick={(e) => {
                e.preventDefault();
                this.props.dispatch(loadOrders(filter, nonPostFilter));
              }}
              className="btn pi-btn-base pi-uppercase"
              style={{marginRight: '10px'}}
            >
              <i className="fa fa-search"></i>&nbsp;Search
            </button> }

            { isDirty && <button
              onClick={(e) => {
                e.preventDefault();
                this.resetFilters();
              }}
              className="btn pi-btn pi-uppercase">
              <i className="fa fa-times"></i>&nbsp;Reset Filters
            </button> }
          </div> }          
          <div className={"pi-col-sm-offset-4 pi-col-sm-4 text-right"}>            
              <h5><small><i>{!orders.loaded ? "" : `Found ${items.length} orders / ${totalAwningCount} awnings`}</i></small></h5>            
          </div>
        </div>


        <div className="pi-row">
          <div className="pi-col-sm-12">
            { loaded ? <ReactTable
              expanded={this.state.expanded}
              /*onExpandedChange={(newExpanded, index, event) => {
                if (newExpanded[index[0]] === false) {
                  newExpanded = {}
                } else {
                  newExpanded = {};
                  for(let i = 0; i < this.state.orders.length; i++){
                    newExpanded[i] = true;
                  }
                }
                this.setState({expanded: newExpanded})
              }}*/
              filterable={false}
              data={items}
              columns={columns}
              sorted={nonPostFilter.sorted}
              page={nonPostFilter.pageIndex}
              pageSize={nonPostFilter.pageSize}
              //filtered={filter.filtered}
              onSortedChange={(newSorted, column, shiftKey) => {
                this.props.dispatch(updateNonPostFilter({ ...nonPostFilter, sorted: [...newSorted] }))
              }}
              onPageChange={(pageIndex) => this.props.dispatch(updateNonPostFilter({ ...nonPostFilter, pageIndex }))}
              onPageSizeChange={(pageSize, pageIndex) => this.props.dispatch(updateNonPostFilter({ ...nonPostFilter, pageIndex, pageSize }))}
              //onFilteredChange={(column, value) => this.props.dispatch(updateFilter({ ...filter, filtered: [...column], pageIndex: 0 }))}
              SubComponent={(row) => {
                return (<div>
                  <ul className="list-group">
                    <li className={'list-group-item list-group-item-success'} style={{ margin: '0px', border: 'inherit' }}>
                      <div className={'pi-col-md-2'} style={{ fontWeight: 'bold' }}>Order Id: </div>
                      <div className={'pi-col-md-2'}>{row.original.id}</div>
                      <div className={'pi-col-md-2'} style={{ fontWeight: 'bold' }}>Est. Completion: </div>
                      <div className={'pi-col-md-4'}>{IsNullOrUndefined(row.original.estimatedDeliveryDate) ? 'N/A' : moment(row.original.estimatedDeliveryDate).format(DATE_SHORT_FORMAT)}</div>
                    </li>
                    {(this.props.user &&
                        this.props.user.hasAccessTag(orders_list_list_all_organisations_all) && row.original.distinctColoursString && row.original.distinctColoursString !== "Custom") &&
                    <li className={'list-group-item list-group-item-success'} style={{ margin: '0px', border: 'inherit' }}>
                      <div className={'pi-col-md-2'} style={{ fontWeight: 'bold' }}>Standard Colours: </div>
                      <div className={'pi-col-md-12'}>{row.original.distinctColoursString}</div>
                      
                    </li>}                    
                    {(this.props.user &&
                      this.props.user.hasAccessTag(orders_list_list_all_organisations_all) && row.original.distinctCustomColoursString) &&
                      <li className={`list-group-item list-group-item-${row.original.pcRecDate && row.original.pcSentDate ? 'success' : 'warning'}`} style={{ margin: '0px', border: 'inherit' }}>
                        <div className={'pi-col-md-2'} style={{ fontWeight: 'bold' }}>PC Colours: </div>
                        <div className={'pi-col-md-6'}>{row.original.distinctCustomColoursString}</div>
                        <div className={'pi-col-md-1'} style={{ fontWeight: 'bold' }}>Sent: </div>
                        <div className={'pi-col-md-1'}>{row.original.pcSentDate ? moment(row.original.pcSentDate).format(DATE_SHORT_FORMAT) : 'to send'}</div>
                        <div className={'pi-col-md-1'} style={{ fontWeight: 'bold' }}>Received: </div>
                        <div className={'pi-col-md-1'}>{row.original.pcRecDate ? moment(row.original.pcRecDate).format(DATE_SHORT_FORMAT) : 'waiting'}</div>
                      </li>}
                    {(this.props.user && this.props.user.hasAccessTag(orders_list_list_all_organisations_all) && row.original.fabricMakerShortName) &&
                      <li className={`list-group-item list-group-item-${row.original.fabricRecDate && row.original.fabricOrderDate ? 'success' : 'warning'}`} style={{ margin: '0px', border: 'inherit' }}>
                        <div className={'pi-col-md-2'} style={{ fontWeight: 'bold' }}>Fabric Sent: </div>
                        <div className={'pi-col-md-1'}>{row.original.fabricOrderDate ? moment(row.original.fabricOrderDate).format(DATE_SHORT_FORMAT) : 'to send'}</div>
                        <div className={'pi-col-md-2'} style={{ fontWeight: 'bold' }}>Fabric Received: </div>
                        <div className={'pi-col-md-1'}>{row.original.fabricRecDate ? moment(row.original.fabricRecDate).format(DATE_SHORT_FORMAT) : 'waiting'}</div>
                      </li>}
                    {row.original.awnings.map(a => (<li key={row.original.id + '_' + a.number} className={`list-group-item list-group-item-info`} style={{ margin: '0px', border: 'inherit' }}>
                      <div className={'pi-col-md-2'} style={{ fontWeight: 'bold' }}>Awning #{(a.number)}: </div>
                      <div className={'pi-col-md-2'}>{a.productDescription}</div>
                      <div className={'pi-col-md-2'}>{a.width}(w) x {a.projection}(p)</div>
                      <div className={'pi-col-md-3'}>{a.driveMechanismDescription}</div>
                      <div className={'pi-col-md-2'}>{a.motorDescription}</div>
                    </li>))}
                  </ul>
                </div>);
              }}
            /> : <div>&nbsp;</div>}
          </div>
        </div>
      </div>);
  }
}

OrdersPage.propTypes = {
  organisations: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    organisations: state.organisations,
    orders: state.orders,
    lookups: state.lookups,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrdersPage));
