import React, { Component } from 'react';
import PropTypes from "prop-types";
import {Route, Switch, withRouter} from "react-router";
import {connect} from "react-redux";
import {
    DATE_SEARCH_FORMAT,
    DATE_SHORT_FORMAT,
    isNullEmptyOrWhitespace,
    IsNullOrUndefined,
    orders_list_list_all_organisations_all,
    SortDate,
    OrderStatuses,
    ROLE_CustomerAdmin_Id,
    ROLE_Administrator,
    ROLE_Administrator_Id,
    ROLE_Fabric,
    ROLE_PC,
    obsfucate,
    Aux,
    SpaceString,
    INVOICE_TYPE_ORDERAWNING,
    INVOICE_TYPE_FABRICRECOVER, ORDER_STATUS_ORDER_DELAYED, POWDER_COATER_TPP_ID, DATE_SUPER_SHORT_FORMAT
} from "../../constants";
import moment from "moment";
import ReactTable from "react-table";
import {toastr} from "react-redux-toastr";
import {apiDownload, apiPost, apiRequest} from "../../utils/request";
import {join, values} from "lodash";
import CheckBoxControl from "../common/CheckBoxControl";
import DatePicker from "react-datepicker";
import SheetWrapperPage from "./SheetWrapperPage";
import {Link} from "react-router-dom";
import Select from "react-select";
import {some, flatten, concat, uniq} from 'lodash';
import {loadSupplierOrders} from "../../actions/supplierOrderActions";
import * as types from "../../actions/actionTypes";
import {FilterProjections, FilterWidths} from "../orders/OrdersPage";
import {sumBy} from "lodash/math";
import {DebounceInput} from "react-debounce-input";
import {saveAs} from 'file-saver';
import {parse} from 'json2csv';

function isSelectedRow(row, order) {
    return order && order.id === row.original.id;
}

const DefaultFilter = {
    fabricMakerId: [],
    powderCoaterId: [],
    organisations: [],
    colours: [],
    products: [],
    includePcOrderAlxReceived: false,
    width: null,
    projection: null,
    fabricMakerIdImpersonate: null,
    powderCoaterIdImpersonate: null,
    motors:[],
    customerRef: '',
    sequenceNumber: '',
    invoiceNumber: '',
    showCustRef: false,
    showMotors: false,
    showInvoiceNumber: false,
};

class JobsPage extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            columns: [],
            items: [],
            selectedOrder: null,
            validationMessages: {},
            loading: true,
            viewMode: 'planning',
            filter: {...DefaultFilter},
        };

        this.setColumns = this.setColumns.bind(this);
        this.loadData = this.loadData.bind(this);
        this.renderEditPcCompleteDate = this.renderEditPcCompleteDate.bind(this);
        this.handleSelectOrder = this.handleSelectOrder.bind(this);
        this.handleSaveOrder = this.handleSaveOrder.bind(this);
        this.handleSaveOrderAlx = this.handleSaveOrderAlx.bind(this);
        this.renderActions = this.renderActions.bind(this);
        this.renderEditFabricCompleteDate = this.renderEditFabricCompleteDate.bind(this);
        this.handleFabricComplete = this.handleFabricComplete.bind(this);
        this.handlePcComplete = this.handlePcComplete.bind(this);
        this.handleComplete = this.handleComplete.bind(this);
        this.handleUpdatedData = this.handleUpdatedData.bind(this);
        this.renderFabricCompleted = this.renderFabricCompleted.bind(this);
        this.renderPcCompleted = this.renderPcCompleted.bind(this);
        this.getUserAccess = this.getUserAccess.bind(this);
        this.setPlanningColumns = this.setPlanningColumns.bind(this);
        this.renderPlanningActions = this.renderPlanningActions.bind(this);
        this.renderEditEstimatedDeliveryDate = this.renderEditEstimatedDeliveryDate.bind(this);
        this.renderDateField = this.renderDateField.bind(this);
        this.renderFabricOrderDate  = this.renderFabricOrderDate.bind(this);
        this.renderFabricRecDate  = this.renderFabricRecDate.bind(this);
        this.renderPcSentDate  = this.renderPcSentDate.bind(this);
        this.renderPcRecDate  = this.renderPcRecDate.bind(this);
        this.handleDispatched = this.handleDispatched.bind(this);
        this.handleFabricDispatched = this.handleFabricDispatched.bind(this);
        this.handlePcDispatched = this.handlePcDispatched.bind(this);
        this.renderFabricDispatched = this.renderFabricDispatched.bind(this);
        this.renderPcDispatched = this.renderPcDispatched.bind(this);
        this.resetFilters = this.resetFilters.bind(this);        
        this.handleProductFilterLink = this.handleProductFilterLink.bind(this); 
        this.handleNotifyEdc =  this.handleNotifyEdc.bind(this);
        this.exportCsv = this.exportCsv.bind(this);
        this.filterItems = this.filterItems.bind(this);
    }

    handleProductFilterLink(e, type) {
        e.preventDefault();

        let {supplierOrders} = this.props;
        let {products} = supplierOrders;
        let {filter} = this.state;

        let newProducts = filter.products;

        if (type === 'all') {
            newProducts = [...products.map(x => x.value)];
        } else if (type === 'FAA') {
            newProducts = ['EC', 'S1', 'S3', 'TG', 'OL', 'DS'];
        } else if (type === 'SD') {
            newProducts = ['SD', 'SM', 'SC', 'SZ'];
        } else if (type === 'ST') {
            newProducts = ['ST'];
        }

        this.setState({
            filter: {
                ...filter,
                products: newProducts,
            }
        }, this.setColumns);
    }

    exportCsv(e){
        e.preventDefault();
        
        let {items, totalAwningCount} = this.filterItems();
        
        let csv = parse(items, {
            flatten: true,
            delimiter: ',',
        });
        let blob = new Blob([csv], {
            type: 'text/csv;charset=utf-8',
        });

        saveAs(blob, 'supplier-jobs-planning.csv');
    }
    
    loadData() {
        
        let {filter} = this.props.supplierOrders;
        
        filter = {...filter, isPcOrderAlxRecieved: this.state.filter.includePcOrderAlxReceived ? null : false};

        this.props.dispatch(loadSupplierOrders(filter));
    }
    resetFilters() {
        this.setState({filter: {...DefaultFilter}}, ()=> this.setColumns());
    }

    handleFilterFieldChange(val, field, onStateUpated = undefined) {
        let filter = { ...this.state.filter };
        filter[field] = val;
        this.setState({filter}, onStateUpated);
    }    

    handleArrayFieldFilterUpdate(v, field){
        let updatedVal = isNullEmptyOrWhitespace(v) ? [] : v.split(',').map(x => parseInt(x));
        this.handleFilterFieldChange(updatedVal, field);
    }

    handleArrayFieldFilterUpdateString(v, field){
        let updatedVal = isNullEmptyOrWhitespace(v) ? [] : v.split(',');
        this.handleFilterFieldChange(updatedVal, field);
    }
    
    handleSelectOrder(order){
        this.setState({selectedOrder: order}, this.setColumns);
    }

    handleSaveOrder(e) {
        let {isPowderCoater, isFabricMaker} = this.getUserAccess();
        if(!(isPowderCoater || isFabricMaker)){
         this.handleSaveOrderAlx(e);
        } else {
            e.preventDefault();

            let {selectedOrder} = this.state;

            apiPost(`/api/supplierOrders/${selectedOrder.id}/supplierDates`, {
                orderId: selectedOrder.id,
                pcSupplierCompleteDate: selectedOrder.pcSupplierCompleteDate,
                fabricSupplierCompleteDate: selectedOrder.fabricSupplierCompleteDate,
                orderType: selectedOrder.orderType,
            }).then(x => {
                this.handleUpdatedData(x.data);
            });
        }
    }
    
    handleNotifyEdc(orderId, edc){
        //eslint-disable-next-line
        if(confirm(`Are you sure that you'd like to notify the customer that the EDC will be ${moment(edc).format(DATE_SHORT_FORMAT)}?`)) {
            apiPost(`/api/orders/${orderId}/notifyEdc`, {}).then(x => {
                toastr.success('Estimated completion date email sent!');
            });
        }
    }

    handleSaveOrderAlx(e) {
        e.preventDefault();

        let {selectedOrder} = this.state;

        apiPost(`/api/supplierOrders/${selectedOrder.id}/alxDates`, {
            orderId: selectedOrder.id,
            estimatedDeliveryDate: selectedOrder.estimatedDeliveryDate,
            fabricOrderDate: selectedOrder.fabricOrderDate,
            fabricRecDate: selectedOrder.fabricRecDate,
            pcSentDate: selectedOrder.pcSentDate,
            pcRecDate: selectedOrder.pcRecDate,
            orderType: selectedOrder.orderType,
        }).then(x => {
            this.handleUpdatedData(x.data);
        });
    }

    handleComplete(id, isFabric, orderType) {
        
        let url = `/api/supplierOrders/${id}/${(isFabric ? 'supplierFabricComplete' : 'supplierPcComplete')}?orderType=${orderType}`;

        apiPost(url)
            .then(x => {
                this.handleUpdatedData(x.data);
            });
    }

    handleDispatched(id, isFabric, orderType) {

        let url = `/api/supplierOrders/${id}/${(isFabric ? 'supplierFabricDispatched' : 'supplierPcDispatched')}?orderType=${orderType}`;

        apiPost(url)
            .then(x => {
                this.handleUpdatedData(x.data);
            });
    }

    handleUpdatedData(data) {
        
        
        if (data) {
            toastr.success('Updated!');
            this.props.dispatch({type: types.SUPPLIER_ORDERS_UPDATE_ORDER, order: data});
            this.setState({selectedOrder: null}, this.setColumns);            
        } else {
            toastr.error('Failed to update');
        }
    }

    handlePcComplete(e, id, orderType){
        e.preventDefault();
        this.handleComplete(id, false, orderType);
    }

    handleFabricComplete(e, id, orderType){
        e.preventDefault();
        this.handleComplete(id, true, orderType);
    }

    handlePcDispatched(e, id, orderType){
        e.preventDefault();
        this.handleDispatched(id, false, orderType);
    }

    handleFabricDispatched(e, id, orderType){
        e.preventDefault();
        this.handleDispatched(id, true, orderType);
    }

    renderEditPcCompleteDate(row) {
        let {isPowderCoater} = this.getUserAccess();

        return isPowderCoater ? this.renderDateField('pcSupplierCompleteDate', row) : <div style={{textAlign: 'center'}}>{row.value}</div>;;
    }

    renderEditFabricCompleteDate(row) {
        let {isFabricMaker} = this.getUserAccess();

        return isFabricMaker ? this.renderDateField('fabricSupplierCompleteDate', row) : <div style={{textAlign: 'center'}}>{row.value}</div>;;
    }

    renderEditEstimatedDeliveryDate(row){
        return this.renderDateField('estimatedDeliveryDate', row, true);
    }

    renderFabricOrderDate(row){
        let {canViewAll, isFabricMaker} = this.getUserAccess();
        return canViewAll && !isFabricMaker ? this.renderDateField('fabricOrderDate', row) : <div style={{textAlign: 'center'}}>{row.value}</div>;
    }

    renderFabricRecDate(row){
        let {canViewAll, isFabricMaker} = this.getUserAccess();
        return canViewAll && !isFabricMaker ?  this.renderDateField('fabricRecDate', row)  : <div style={{textAlign: 'center'}}>{row.value}</div>;
    }

    renderPcSentDate(row){
        let {canViewAll, isPowderCoater} = this.getUserAccess();
        return canViewAll && !isPowderCoater ?  this.renderDateField('pcSentDate', row) : <div style={{textAlign: 'center'}}>{row.value}</div>;
    }

    renderPcRecDate(row){
        let {canViewAll, isPowderCoater} = this.getUserAccess();
        return canViewAll && !isPowderCoater ?  this.renderDateField('pcRecDate', row) : <div style={{textAlign: 'center'}}>{row.value}</div>;
    }

    renderDateField(fieldName, row, showDelayed=false){
        let {selectedOrder} = this.state;

        return isSelectedRow(row, selectedOrder) ? <DatePicker
            onChange={(d) => {
                let state = {selectedOrder: {...selectedOrder}};
                state.selectedOrder[fieldName] = d ? d.format() : null;
                this.setState(state);
            }}
            selected={IsNullOrUndefined(selectedOrder[fieldName]) ? null : moment(selectedOrder[fieldName])}
            name={fieldName}
            dateFormat={DATE_SHORT_FORMAT}
            minDate={moment()}
            onChangeRaw={(e) => {
                e.target.value = '';
            }}
            dropdownMode={"scroll"}
        /> : <div style={{textAlign: 'center'}}>{row.value}{showDelayed && !isNullEmptyOrWhitespace(row.original.delayedReason) ? <i className={"ml-2 text-info fa fa-lg fa-info-circle"} data-toggle="tooltip" title={row.original.delayedReason} /> : null}</div>;
    }

    renderActions(row) {
        let {selectedOrder, validationMessages} = this.state;
        let {showFabric, showPc} = this.getUserAccess();

        return (!isSelectedRow(row, selectedOrder) ?
                <span>
                                    <a style={{color: '#f58b3c', cursor: 'pointer'}} onClick={(e) => {
                                        e.preventDefault();
                                        this.handleSelectOrder(row.original);
                                    }}>
                                        <i className="fa fa-edit fa-lg"/>
                                    </a>

                                    {showFabric && [...Array(row.original.numberOfFabrics).keys()].map(f => {
                                        return (<a key={f} style={{color: '#f58b3c', cursor: 'pointer', marginLeft: '5px'}}
                                                   title={`View Fabric Sheet ${f+1}`}
                                                   onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.history.push(`/supplier/jobs/${obsfucate(row.original.id)}/fabricsheet/${f}/${row.original.orderType}/0`);
                                                        }}>
                                                                            <i className="fa fa-binoculars fa-lg"/>
                                                                        </a>);}) }

                    {showPc && [...Array(row.original.numberOfCustomColours).keys()].map(f => {
                        return (<a key={f} style={{color: '#f58b3c', cursor: 'pointer', marginLeft: '5px'}}
                                   title={`View PC Sheet ${f+1}`}
                                   onClick={(e) => {
                                       e.preventDefault();
                                       this.props.history.push(`/supplier/jobs/${obsfucate(row.original.id)}/pcsheet/${f}/${row.original.orderType}/0`);
                                   }}>
                            <i className="fa fa-paint-brush fa-lg"/>
                        </a>);}) }

                    {showPc 
                        && row.original.powderCoaterId === POWDER_COATER_TPP_ID 
                        && !IsNullOrUndefined(row.original.pcSupplierCompletedDate) 
                        && [...Array(row.original.numberOfCustomColours).keys()].map(f => {
                        return (<a key={f} style={{color: '#f58b3c', cursor: 'pointer', marginLeft: '5px'}}
                                   title={`Download PC Sheet ${f+1}`}
                                   onClick={(e) => {
                                       e.preventDefault();
                                       this.props.history.push(`/supplier/jobs/${obsfucate(row.original.id)}/pcsheet/${f}/${row.original.orderType}/1`);
                                   }}>
                            <i className="fa fa-download fa-lg"/>
                        </a>);}) }
                                </span> :
                <span>
                                {values(validationMessages).length === 0 &&
                                <a style={{color: '#f58b3c', cursor: 'pointer'}} onClick={this.handleSaveOrder}>
                                    Save
                                </a>}
                    <a style={{cursor: 'pointer', marginLeft: '5px', fontSize: 'smaller'}}
                       onClick={(e) => {
                           e.preventDefault();
                           this.handleSelectOrder(null);
                       }}>
                                    Cancel
                </a>
              </span>
        );
    }

    renderPlanningActions(row) {
        let {selectedOrder, validationMessages} = this.state;
        // `/supplier/jobs/${obsfucate(row.original.id)}/pcsheet/${f}/${row.original.orderType}
        let openOrderURl = row.original.orderType === INVOICE_TYPE_ORDERAWNING ? 'orders/view' : row.original.orderType === INVOICE_TYPE_FABRICRECOVER ? 'fabricRecoverOrders/edit' : '';
        return (!isSelectedRow(row, selectedOrder) ?
                <span>
                                    <a style={{color: '#f58b3c', cursor: 'pointer'}} onClick={(e) => {
                                        e.preventDefault();
                                        this.handleSelectOrder(row.original);
                                    }}>
                                        <i className="fa fa-edit fa-lg"/>
                                    </a>

                    { isNullEmptyOrWhitespace(openOrderURl) ?
                        (<a style={{color: '#f58b3c', cursor: 'pointer', marginLeft: '5px'}}
                            title={`View PC Sheet`}
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push(`/supplier/jobs/${obsfucate(row.original.id)}/pcsheet/0/${row.original.orderType}/0`);
                            }}>
                            <i className="fa fa-paint-brush fa-lg"/>
                        </a>)
                        : (<a href={`#/${openOrderURl}/${obsfucate(row.original.id)}`}
                              target="_blank"
                              title={'Open Order'}
                              style={{ marginLeft: '10px' }}
                        ><i className="fa fa-external-link fa-lg" /></a>) }

                    {!IsNullOrUndefined(row.original.estimatedDeliveryDate) &&
                        <a onClick={(e) => {
                            e.preventDefault();
                            this.handleNotifyEdc(row.original.id, row.original.estimatedDeliveryDate);
                        }}
                            title={'Notify EDC'}
                            style={{ marginLeft: '10px', color: '#f58b3c', cursor: 'pointer' }}
                        ><i className="fa fa-envelope fa-lg" /></a>}
                    
                                </span> :
                <span>
                                {values(validationMessages).length === 0 &&
                                <a style={{color: '#f58b3c', cursor: 'pointer'}} onClick={this.handleSaveOrderAlx}>
                                    Save
                                </a>}
                    <a style={{cursor: 'pointer', marginLeft: '5px', fontSize: 'smaller'}}
                       onClick={(e) => {
                           e.preventDefault();
                           this.handleSelectOrder(null);
                       }}>
                                    Cancel
                </a>
              </span>
        );
    }

    renderFabricCompleted(row) {
        let {selectedOrder} = this.state;
        let {isFabricMaker} = this.getUserAccess();

        return IsNullOrUndefined(row.value) && isNullEmptyOrWhitespace(selectedOrder) && isFabricMaker ?
            <div style={{textAlign: 'center'}}>
                <button
                    className="btn pi-btn-base"
                    onClick={(e) => this.handleFabricComplete(e, row.original.id, row.original.orderType)}>
                    <i className="fa fa-check"/>&nbsp;Complete
                </button>
            </div> :
            <div style={{textAlign: 'center'}}>{row.value}</div>;
    }

    renderPcCompleted(row) {
        let {selectedOrder} = this.state;
        let {isPowderCoater} = this.getUserAccess();

        return IsNullOrUndefined(row.value) && isNullEmptyOrWhitespace(selectedOrder) && isPowderCoater ?
            <div style={{textAlign: 'center'}}>
                <button
                    className="btn pi-btn-base"
                    onClick={(e) => this.handlePcComplete(e, row.original.id, row.original.orderType)}>
                    <i className="fa fa-check"/>&nbsp;Complete
                </button>
            </div> :
            <div style={{textAlign: 'center'}}>{row.value}</div>;
    }

    renderFabricDispatched(row) {
        let {selectedOrder} = this.state;

        return IsNullOrUndefined(row.value) && isNullEmptyOrWhitespace(selectedOrder) ?
            <div style={{textAlign: 'center'}}>
                <button
                    className="btn pi-btn-base"
                    onClick={(e) => this.handleFabricDispatched(e, row.original.id, row.original.orderType)}>
                    <i className="fa fa-check"/>&nbsp;Dispatched
                </button>
            </div> :
            <div style={{textAlign: 'center'}}>{row.value}</div>;
    }

    renderPcDispatched(row) {
        let {selectedOrder} = this.state;

        return IsNullOrUndefined(row.value) && isNullEmptyOrWhitespace(selectedOrder) ?
            <div style={{textAlign: 'center'}}>
                <button
                    className="btn pi-btn-base"
                    onClick={(e) => this.handlePcDispatched(e, row.original.id, row.original.orderType)}>
                    <i className="fa fa-check"/>&nbsp;Dispatched
                </button>
            </div> :
            <div style={{textAlign: 'center'}}>{row.value}</div>;
    }

    setColumns() {
        let {isFabricMaker, isPowderCoater, canViewAll, showFabric, showPc, showPlanning} = this.getUserAccess();
        let {user} = this.props;
        let showSequenceNumber = IsNullOrUndefined(user.organisation.fabricMakerShowSequenceNumber) || user.organisation.fabricMakerShowSequenceNumber;

        if(showPlanning){
            this.setPlanningColumns();
        } else {
            this.setState({
                columns: [{
                    id: 'sequenceNumber',
                    Header: showSequenceNumber ? 'PN' : 'Cust. Ref.',
                    accessor: d => showSequenceNumber ? d.sequenceNumber : d.orderCustomerReference,
                    maxWidth: showSequenceNumber ? 80 : 140,                    
                }, {
                    Header: 'Cust. Ref',
                    accessor: 'orderCustomerReference',
                    maxWidth: 90,
                    show: canViewAll && !isFabricMaker && !isPowderCoater,
                }, {
                    Header: 'Fabric',
                    id: 'fabricMakerShortName',
                    accessor: d => isNullEmptyOrWhitespace(d.fabricMakerShortName) ? 'N/A' : d.fabricMakerShortName,
                    maxWidth: 60,
                    show: showFabric && canViewAll,
                },
                    {
                        Header: `PC'er`,
                        accessor: 'powderCoaterShortName',
                        maxWidth: 60,
                        show: showPc && canViewAll,
                    },
                    // {
                    //     Header: `Fabrics`,
                    //     accessor: 'numberOfFabrics',
                    //     show: showFabric,
                    //     sortMethod: (a,b) => {
                    //         return a < b ? -1 : 1;
                    //     },
                    //     maxWidth: 80,
                    // },
                    {
                        Header: `Items`,
                        accessor: 'numberOfFabricItems',
                        show: showFabric,
                        sortMethod: (a,b) => {
                            return a < b ? -1 : 1;
                        },
                        maxWidth: 80,
                    },
                    {
                        Header: `Fabric`,
                        accessor: 'distinctFabricString',
                        show: showFabric
                    },{
                        Header: `Colour`,
                        accessor: 'distinctCustomColoursString',
                        show: showPc
                    },
                    {
                        id: 'fabricOrderDate',
                        Header: `Order Sent`,
                        accessor: d => IsNullOrUndefined(d.fabricOrderDate) ? null : moment(d.fabricOrderDate).format(DATE_SHORT_FORMAT),
                        sortMethod: SortDate,
                        maxWidth: 120,
                        show: showFabric,
                        Cell: this.renderFabricOrderDate,
                    }, {
                        id: 'fabricSupplierCompleteDate',
                        Header: `Scheduled ETA`,
                        accessor: d => IsNullOrUndefined(d.fabricSupplierCompleteDate) ? null : moment(d.fabricSupplierCompleteDate).format(DATE_SHORT_FORMAT),
                        sortMethod: SortDate,
                        Cell: this.renderEditFabricCompleteDate,
                        maxWidth: 125,
                        show: showFabric,
                    }, {
                        id: 'fabricSupplierCompletedDate',
                        Header: `Fabric Completed`,
                        accessor: d => IsNullOrUndefined(d.fabricSupplierCompletedDate) ? null : moment(d.fabricSupplierCompletedDate).format(DATE_SHORT_FORMAT),
                        sortMethod: SortDate,
                        Cell: this.renderFabricCompleted,
                        maxWidth: 175,
                        show: showFabric,
                    }, {
                        id: 'fabricSupplierDispatchedDate',
                        Header: `Fabric Dispatched`,
                        accessor: d => IsNullOrUndefined(d.fabricSupplierDispatchedDate) ? null : moment(d.fabricSupplierDispatchedDate).format(DATE_SHORT_FORMAT),
                        sortMethod: SortDate,
                        Cell: this.renderFabricDispatched,
                        maxWidth: 175,
                        show: showFabric && isFabricMaker,
                    },
                    {
                        id: 'fabricRecDate',
                        Header: `ALX Received`,
                        accessor: d => IsNullOrUndefined(d.fabricRecDate) ? null : moment(d.fabricRecDate).format(DATE_SHORT_FORMAT),
                        sortMethod: SortDate,
                        maxWidth: 120,
                        show: showFabric && canViewAll && !isFabricMaker,
                        Cell: this.renderFabricRecDate,
                    },
                    {
                        id: 'pcSentDate',
                        Header: `Order Sent`,
                        accessor: d => IsNullOrUndefined(d.pcSentDate) ? null : moment(d.pcSentDate).format(DATE_SHORT_FORMAT),
                        sortMethod: SortDate,
                        maxWidth: 120,
                        show: showPc,
                        Cell: this.renderPcSentDate,
                    }, {
                        id: 'pcSupplierCompleteDate',
                        Header: `Scheduled PC date`,
                        accessor: d => IsNullOrUndefined(d.pcSupplierCompleteDate) ? null : moment(d.pcSupplierCompleteDate).format(DATE_SHORT_FORMAT),
                        sortMethod: SortDate,
                        Cell: this.renderEditPcCompleteDate,
                        maxWidth: 145,
                        show: showPc,
                    }, {
                        id: 'pcSupplierCompletedDate',
                        Header: `PC Completed`,
                        accessor: d => IsNullOrUndefined(d.pcSupplierCompletedDate) ? null : moment(d.pcSupplierCompletedDate).format(DATE_SHORT_FORMAT),
                        sortMethod: SortDate,
                        Cell: this.renderPcCompleted,
                        maxWidth: 175,
                        show: showPc,
                    }, 
                    // {
                    //     id: 'pcSupplierDispatcheddDate',
                    //     Header: `PC Dispatched`,
                    //     accessor: d => IsNullOrUndefined(d.pcSupplierDispatcheddDate) ? null : moment(d.pcSupplierDispatcheddDate).format(DATE_SHORT_FORMAT),
                    //     sortMethod: SortDate,
                    //     Cell: this.renderPcDispatched,
                    //     maxWidth: 175,
                    //     show: showPc && isPowderCoater,
                    // },
                    {
                        id: 'pcRecDate',
                        Header: `ALX Received`,
                        accessor: d => IsNullOrUndefined(d.pcRecDate) ? null : moment(d.pcRecDate).format(DATE_SHORT_FORMAT),
                        sortMethod: SortDate,
                        maxWidth: 120,
                        show: showPc && canViewAll && !isPowderCoater,
                        Cell: this.renderPcRecDate,
                    },
                    {
                        id: 'createdDate',
                        Header: `Date`,
                        accessor: d => moment(d.orderDisplayDate).format(DATE_SHORT_FORMAT),
                        sortMethod: SortDate,
                        maxWidth: 120,
                        show: false,
                    },
                    {
                        id: 'actions',
                        Header: '',
                        Cell: this.renderActions,
                        maxWidth: 125,
                    }]
            });
        }

    }

    setPlanningColumns() {
        let {isFabricMaker, isPowderCoater, canViewAll} = this.getUserAccess();
        let {filter} = this.state;
        let renderCompletedTick = (row) => (<div style={{textAlign: 'center'}}>{!IsNullOrUndefined(row.value) &&
        <i className={`fa fa-${IsNullOrUndefined(row.value) ? 'times' : 'check'} ${IsNullOrUndefined(row.value) ? 'text-danger' : 'text-success'}`}/>}</div>);

        this.setState({
            columns: [{
                id: 'sequenceNumber',
                Header: 'PN',
                accessor: d => d.sequenceNumber,
                maxWidth: 80,
            },{
                id: 'orderAcceptedDate',
                Header: 'Acc. Date',
                accessor: d => IsNullOrUndefined(d.orderAcceptedDate) ? '' : moment(d.orderAcceptedDate).format(DATE_SUPER_SHORT_FORMAT),
                sortMethod: SortDate,
                maxWidth: 80,
            }, {
                Header: 'Org.',
                accessor: 'organisationShortName',
                maxWidth: 90,
            }, {
                Header: 'Cust. Ref',
                accessor: 'orderCustomerReference',
                maxWidth: 90,
                show: (canViewAll && !isFabricMaker && !isPowderCoater) && filter.showCustRef,
            }, {
                Header: 'Inv. No.',
                accessor: 'orderInvoiceNumber',
                maxWidth: 90,
                show: (canViewAll && !isFabricMaker && !isPowderCoater) && filter.showInvoiceNumber,
            }, {
                Header: `Product`,
                accessor: 'awningProducts',
                maxWidth: 85,
            }, {
                Header: `Colour`,
                accessor: 'distinctCustomColoursString',                
            }, {
                id: 'widthText',
                Header: `Widths`,
                accessor: d => d.widthText,
                maxWidth: 70,
            }, {
                id: 'projectionText',
                Header: `Proj.`,
                accessor: d => d.projectionText,
                maxWidth: 70,
            }, {
                id: 'motorText',
                Header: `Motors`,
                accessor: d => d.motorText,
                show: filter.showMotors,
            },
            {
                Header: 'Fabric',
                id: 'fabricMakerShortName',
                accessor: d => isNullEmptyOrWhitespace(d.fabricMakerShortName) ? 'N/A' : d.fabricMakerShortName,
                maxWidth: 60,
            }, {
                id: 'powderCoaterShortName',
                Header: `PC'er`,
                accessor: d => IsNullOrUndefined(d.powderCoaterShortName) && !isNullEmptyOrWhitespace(d.distinctCustomColoursString) ? 'Stock' : d.powderCoaterShortName,
                maxWidth: 60,
            }, {
                id: 'fabricStatusText',
                Header: `Fab. Status`,
                //accessor: d => IsNullOrUndefined(d.fabricOrderDate) ? null : moment(d.fabricOrderDate).format(DATE_SHORT_FORMAT),
                accessor: d => d.fabricStatusText,
                //sortMethod: SortDate,
                maxWidth: 130,
                //Cell: d => <span>{d.original.fabricStatusText}</span>
            }, {
                id: 'pcStatusText',
                Header: `PC Status`,
                accessor: d => d.pcStatusText,
                maxWidth: 135,
            },
            {
                id: 'estimatedDeliveryDate',
                Header: `EDC`,
                accessor: d => d.orderStatus === ORDER_STATUS_ORDER_DELAYED ? 'Delayed' : IsNullOrUndefined(d.estimatedDeliveryDate) ? null : moment(d.estimatedDeliveryDate).format(DATE_SUPER_SHORT_FORMAT),
                sortMethod: SortDate,
                Cell: this.renderEditEstimatedDeliveryDate,
                maxWidth: 130,
                show: true,
            },
            {
                id: 'actions',
                Header: '',
                Cell: this.renderPlanningActions,
                maxWidth: 100,
            }
                // {
                //     id: 'fabricSupplierCompleteDate',
                //     Header: `Sched. Fab. Date`,
                //     accessor: d => IsNullOrUndefined(d.fabricSupplierCompleteDate) ? null : moment(d.fabricSupplierCompleteDate).format(DATE_SHORT_FORMAT),
                //     sortMethod: SortDate,
                //     maxWidth: 175,
                // },
                // {
                //     id: 'fabricSupplierCompletedDate',
                //     Header: `Fab. Comp.`,
                //     accessor: d => IsNullOrUndefined(d.fabricSupplierCompletedDate) ? null : moment(d.fabricSupplierCompletedDate).format(DATE_SHORT_FORMAT),
                //     sortMethod: SortDate,
                //     Cell: renderCompletedTick,
                //     maxWidth: 100,
                // },
                // , {
                //     id: 'pcSupplierCompleteDate',
                //     Header: `Sched. PC date`,
                //     accessor: d => IsNullOrUndefined(d.pcSupplierCompleteDate) ? null : moment(d.pcSupplierCompleteDate).format(DATE_SHORT_FORMAT),
                //     sortMethod: SortDate,
                //     maxWidth: 175,
                // }                              
                // , {
                //     id: 'pcSupplierCompletedDate',
                //     Header: `PC Comp.`,
                //     accessor: d => IsNullOrUndefined(d.pcSupplierCompletedDate) ? null : moment(d.pcSupplierCompletedDate).format(DATE_SHORT_FORMAT),
                //     sortMethod: SortDate,
                //     Cell: renderCompletedTick,
                //     maxWidth: 100,
                // },
                ]
        });
    }

    componentDidMount() {
        this.setColumns();
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(JSON.stringify(prevProps.user) !== JSON.stringify(this.props.user)){            
            this.setColumns();
        }        
    }

    getUserAccess(){
        let {user} = this.props;
        let {viewMode, filter} = this.state;
        
        let canViewAll = user && user.hasAccessTag(orders_list_list_all_organisations_all);
        let isFabricMaker = (canViewAll && filter.fabricMakerIdImpersonate !== null) || (user && user.isInRole(ROLE_Fabric));
        let isPowderCoater = (canViewAll && filter.powderCoaterIdImpersonate !== null) || (user && user.isInRole(ROLE_PC));

        if(!canViewAll && !(isFabricMaker && isPowderCoater)) {
            if (isFabricMaker) {
                viewMode = 'fabric';
            }
            if (isPowderCoater) {
                viewMode = 'pc';
            }
        }
        let showFabric = viewMode === 'fabric';
        let showPc = viewMode === 'pc';
        let showPlanning = viewMode === 'planning';

        return {canViewAll, isFabricMaker, isPowderCoater, viewMode, showPc, showFabric, showPlanning};
    }
    
    filterItems(){
        let {columns, filter} = this.state;
        let {isFabricMaker, isPowderCoater, canViewAll, viewMode, showFabric, showPc} = this.getUserAccess();
        let {user, lookups, supplierOrders} = this.props;

        let {items, colours, products, organisations, motors} = supplierOrders;
        let loading = !supplierOrders.loaded;

        items = items
            .filter(x => !IsNullOrUndefined(x.fabricMakerId) && showFabric || !showFabric)
            .filter(x => !IsNullOrUndefined(x.powderCoaterId) && showPc || !showPc)
            .filter(x => (isFabricMaker && IsNullOrUndefined(x.fabricRecDate)) || (canViewAll && IsNullOrUndefined(filter.fabricMakerIdImpersonate)) || isPowderCoater)
            .filter(x => (isPowderCoater && IsNullOrUndefined(x.pcRecDate)) || (canViewAll && IsNullOrUndefined(filter.powderCoaterIdImpersonate)) || isFabricMaker);

        if((viewMode==='planning' || viewMode==='fabric') && canViewAll){
            items = items.filter(x => filter.fabricMakerId.length === 0 || some(filter.fabricMakerId, y => ((y === x.fabricMakerId) || (y===-1 && x.fabricMakerId===null))));
        }
        if((viewMode==='planning' || viewMode==='pc') && canViewAll) {
            items = items.filter(x => filter.powderCoaterId.length === 0 || some(filter.powderCoaterId, y => y === x.powderCoaterId) ||
                (IsNullOrUndefined(x.powderCoaterShortName) && some(filter.powderCoaterId, y => y === 5))) //Stock is when there is no powdercoater
        }
        items = items.filter(x => filter.organisations.length === 0 || some(filter.organisations, y => y === x.organisationShortName));
        items = items.filter(x => filter.colours.length === 0 || some(filter.colours,
            y => some(x.distinctColours, z => z === y) ||
                some(x.distinctCustomColours, z => z === y)));

        items = items.filter(x => filter.products.length === 0 || some(filter.products,
            y => some(x.products, z => z === y)));

        items = items.filter(x => filter.motors.length === 0 || some(filter.motors,
            y => some(x.motors, z => {
                return z.id === parseInt(y);
            })));

        items = items.filter(x => IsNullOrUndefined(filter.width) || some(x.widths, z => z >= filter.width.value.minWidth && z<= filter.width.value.maxWidth));
        items = items.filter(x => IsNullOrUndefined(filter.projection) || some(x.projections, z => z >= filter.projection.value.minWidth && z<= filter.projection.value.maxWidth));

        if(canViewAll && !isNullEmptyOrWhitespace(filter.customerRef)){
            items = items.filter(x => !isNullEmptyOrWhitespace(x.orderCustomerReference) && x.orderCustomerReference.toLowerCase().includes(filter.customerRef.toLowerCase()))
        }

        if(canViewAll && !isNullEmptyOrWhitespace(filter.sequenceNumber)){
            items = items.filter(x => !isNullEmptyOrWhitespace(x.sequenceNumber) && x.sequenceNumber.toLowerCase().includes(filter.sequenceNumber.toLowerCase()))
        }
        if(canViewAll && !isNullEmptyOrWhitespace(filter.invoiceNumber)){
            items = items.filter(x => !isNullEmptyOrWhitespace(x.orderInvoiceNumber) && x.orderInvoiceNumber.toLowerCase().includes(filter.invoiceNumber.toLowerCase()))
        }

        let totalAwningCount = sumBy(items, x => x.awningCount);
        
        return {items, totalAwningCount};
    }

    render() {
        let {columns, filter} = this.state;
        let {isFabricMaker, isPowderCoater, canViewAll, viewMode, showFabric, showPc} = this.getUserAccess();
        let {user, lookups, supplierOrders} = this.props;        
        
        let {colours, products, organisations, motors} = supplierOrders;
        let loading = !supplierOrders.loaded;
        
        let {items, totalAwningCount} = this.filterItems();

        return (<div style={{marginTop: '25px', marginBottom: '50px'}}>

            { canViewAll && <div className="form-check-inline">
                <input className="form-check-input" type={'radio'} name={'supplierViewMode'} value={'PC'} checked={viewMode==='planning'} onChange={(e) => {
                    this.setState({
                        viewMode: 'planning',
                        filter: {
                            ...this.state.filter,
                            powderCoaterIdImpersonate: null,
                            fabricMakerIdImpersonate: null
                        }
                    }, this.setColumns);
                }} />
                <label
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                            viewMode: 'planning',
                            filter: {
                                ...this.state.filter,
                                powderCoaterIdImpersonate: null,
                                fabricMakerIdImpersonate: null
                            }
                        }, this.setColumns);
                    }}
                    style={{cursor: "pointer"}}
                    className="form-check-label" htmlFor="supplierViewMode">
                    Planning view
                </label>
            </div> }
            
            {(canViewAll || (isPowderCoater && isFabricMaker)) && <Aux>
                <div className="form-check-inline">
                    <input className="form-check-input" type={'radio'} name={'supplierViewMode'} value={'Fabric'} checked={viewMode==='fabric'} onChange={(e) => {
                        this.setState({viewMode: 'fabric'}, this.setColumns);
                    }} />
                    <label 
                        onClick={(e) => {
                            e.preventDefault();
                            this.setState({viewMode: 'fabric'}, this.setColumns);
                            }}
                        style={{cursor: "pointer"}}
                           className="form-check-label" htmlFor="supplierViewMode"
                    >
                        Fabric view
                    </label>
                </div>

                <div className="form-check-inline">
                    <input className="form-check-input" type={'radio'} name={'supplierViewMode'} value={'PC'} checked={viewMode==='pc'} onChange={(e) => {
                        this.setState({viewMode: 'pc'}, this.setColumns);
                    }} />
                    <label
                        onClick={(e) => {
                            e.preventDefault();
                            this.setState({viewMode: 'pc'}, this.setColumns);
                        }}
                        style={{cursor: "pointer"}}
                        className="form-check-label" htmlFor="supplierViewMode">
                        PC view
                    </label>
                </div>
                
            </Aux> }

            { canViewAll && 
                <Aux>

                    {(viewMode === 'fabric') &&
                    <div className={'row mb-4 mt-3'}>
                        <div className={'col-6'}>
                            <Select
                                clearable={true}
                                deleteRemoves={true}
                                backspaceRemoves={true}
                                name="fabricMakerId"
                                options={lookups.fabricMakers}
                                multi={true}
                                simpleValue={true}
                                onChange={(val) => this.handleArrayFieldFilterUpdate(val, 'fabricMakerId')}
                                value={filter.fabricMakerId}
                                placeholder="Fabric Makers"
                                disabled={!IsNullOrUndefined(filter.fabricMakerIdImpersonate)}
                            />
                            { !isFabricMaker && <DebounceInput
                                minLength={0}
                                debounceTimeout={250}
                                type={'text'}
                                className={'form-control'}
                                value={filter.customerRef}
                                onChange={(val) => this.handleFilterFieldChange(val.target.value, 'customerRef')}
                                name={"customerRef"}
                                placeholder={"Customer Ref"}
                                style={{marginTop: '7px'}}
                            />}
                        </div>
                        <div className={'col-6'}>
                            <Select                                
                                clearable={true}
                                deleteRemoves={true}
                                backspaceRemoves={true}
                                name="fabricMakerIdImpersonate"
                                options={lookups.fabricMakers}
                                multi={false}
                                simpleValue={true}
                                onChange={(val) => this.handleFilterFieldChange(val, 'fabricMakerIdImpersonate', ()=>{
                                    if(!IsNullOrUndefined(this.state.filter.fabricMakerIdImpersonate)){
                                        this.setState({filter: {...this.state.filter, fabricMakerId: [this.state.filter.fabricMakerIdImpersonate], powderCoaterId: [], powderCoaterIdImpersonate: null, customerRef: '', sequenceNumber: '', invoiceNumber: ''}}, this.setColumns);
                                    } else {
                                        this.setState({filter: {...this.state.filter, customerRef: '', sequenceNumber: '', invoiceNumber: ''}}, this.setColumns);
                                    }
                                })}
                                value={filter.fabricMakerIdImpersonate}
                                placeholder="Impersonate Fabric Maker"
                            />
                            { !isFabricMaker && <DebounceInput
                                minLength={0}
                                debounceTimeout={250}
                                type={'text'}
                                className={'form-control'}
                                value={filter.sequenceNumber}
                                onChange={(val) => this.handleFilterFieldChange(val.target.value, 'sequenceNumber')}
                                name={"sequenceNumber"}
                                placeholder={"PN"}
                                style={{marginTop: '7px'}}
                            /> }
                        </div>
                    </div>
                    }

                    {(viewMode === 'pc') &&
                    <div className={'row mb-4 mt-3'}>
                        <div className={'col-6'}>
                            <Select                                
                                clearable={true}
                                deleteRemoves={true}
                                backspaceRemoves={true}
                                name="powderCoaterId"
                                options={lookups.powderCoaters}
                                multi={true}
                                simpleValue={true}
                                onChange={(val) => this.handleArrayFieldFilterUpdate(val, 'powderCoaterId')}
                                value={filter.powderCoaterId}
                                placeholder="Powdercoaters"
                                disabled={!IsNullOrUndefined(filter.powderCoaterIdImpersonate)}
                            />
                            { !isPowderCoater && <DebounceInput
                                minLength={0}
                                debounceTimeout={250}
                                type={'text'}
                                className={'form-control'}
                                value={filter.customerRef}
                                onChange={(val) => this.handleFilterFieldChange(val.target.value, 'customerRef')}
                                name={"customerRef"}
                                placeholder={"Customer Ref"}
                                style={{marginTop: '7px'}}
                            /> }
                            <Select
                                className={"mt-2"}
                                clearable={true}
                                deleteRemoves={true}
                                backspaceRemoves={true}
                                name="products"
                                options={products}
                                multi={true}
                                simpleValue={true}
                                onChange={(val) => this.handleArrayFieldFilterUpdateString(val, 'products')}
                                value={filter.products}
                                placeholder="Products"
                            />
                        </div>
                        <div className={'col-6'}>
                            <Select                                
                                clearable={true}
                                deleteRemoves={true}
                                backspaceRemoves={true}
                                name="powderCoaterIdImpersonate"
                                options={lookups.powderCoaters}
                                multi={false}
                                simpleValue={true}
                                onChange={(val) => this.handleFilterFieldChange(val, 'powderCoaterIdImpersonate', ()=>{
                                    if(!IsNullOrUndefined(this.state.filter.powderCoaterIdImpersonate)){
                                        this.setState({filter: {...this.state.filter, powderCoaterId: [this.state.filter.powderCoaterIdImpersonate], fabricMakerId: [], fabricMakerIdImpersonate: null, customerRef: '', sequenceNumber: ''}}, this.setColumns);
                                    } else {
                                        this.setState({filter: {...this.state.filter, customerRef: '', sequenceNumber: ''}}, this.setColumns);
                                    }
                                })}
                                value={filter.powderCoaterIdImpersonate}
                                placeholder="Impersonate Powdercoater"
                            />
                            { !isPowderCoater && <DebounceInput
                                minLength={0}
                                debounceTimeout={250}
                                type={'text'}
                                className={'form-control'}
                                value={filter.sequenceNumber}
                                onChange={(val) => this.handleFilterFieldChange(val.target.value, 'sequenceNumber')}
                                name={"sequenceNumber"}
                                placeholder={"PN"}
                                style={{marginTop: '7px'}}
                            /> }
                            <Select
                                className={"mt-2"}
                                clearable={true}
                                deleteRemoves={true}
                                backspaceRemoves={true}
                                name="colours"
                                options={colours}
                                multi={true}
                                simpleValue={true}
                                onChange={(val) => this.handleArrayFieldFilterUpdateString(val, 'colours')}
                                value={filter.colours}
                                placeholder="Colours"
                            />
                        </div>
                    </div>
                    }                    


                    {(viewMode === 'planning') &&
                    <Aux>                        
                        <div className={'row mb-4 mt-3'}>
                            <div className={'col-6'}>
                                <Select
                                    clearable={true}
                                    deleteRemoves={true}
                                    backspaceRemoves={true}
                                    name="fabricMakerId"
                                    options={lookups.fabricMakers}
                                    multi={true}
                                    simpleValue={true}
                                    onChange={(val) => this.handleArrayFieldFilterUpdate(val, 'fabricMakerId')}
                                    value={filter.fabricMakerId}
                                    placeholder="Fabric Makers"
                                    disabled={!IsNullOrUndefined(filter.fabricMakerIdImpersonate)}
                                />
                                { !isPowderCoater && !isFabricMaker && <DebounceInput
                                    minLength={0}
                                    debounceTimeout={250}
                                    type={'text'}
                                    className={'form-control'}
                                    value={filter.customerRef}
                                    onChange={(val) => this.handleFilterFieldChange(val.target.value, 'customerRef')}
                                    name={"customerRef"}
                                    placeholder={"Customer Ref"}
                                    style={{marginTop: '7px'}}
                                />        }
                                { !isPowderCoater && !isFabricMaker &&  <DebounceInput
                                    minLength={0}
                                    debounceTimeout={250}
                                    type={'text'}
                                    className={'form-control'}
                                    value={filter.invoiceNumber}
                                    onChange={(val) => this.handleFilterFieldChange(val.target.value, 'invoiceNumber')}
                                    name={"invoiceNumber"}
                                    placeholder={"Invoice Number"}
                                    style={{marginTop: '7px'}}
                                />}
                                <Select
                                    className={"mt-2"}
                                    clearable={true}
                                    deleteRemoves={true}
                                    backspaceRemoves={true}
                                    name="width"
                                    options={FilterWidths}
                                    multi={false}
                                    simpleValue={false}
                                    onChange={(val) => this.handleFilterFieldChange(val, 'width')}
                                    value={filter.width}
                                    placeholder="Width"
                                />
                                <Select
                                    className={"mt-2"}
                                    clearable={true}
                                    deleteRemoves={true}
                                    backspaceRemoves={true}
                                    name="colours"
                                    options={colours}
                                    multi={true}
                                    simpleValue={true}
                                    onChange={(val) => this.handleArrayFieldFilterUpdateString(val, 'colours')}
                                    value={filter.colours}
                                    placeholder="Colours"
                                />
                                <Select
                                    className={"mt-2"}
                                    clearable={true}
                                    deleteRemoves={true}
                                    backspaceRemoves={true}
                                    name="products"
                                    options={products}
                                    multi={true}
                                    simpleValue={true}
                                    onChange={(val) => this.handleArrayFieldFilterUpdateString(val, 'products')}
                                    value={filter.products}
                                    placeholder="Products"
                                />
                                <div className={"mt-2"}>
                                    <a href={''} onClick={e => this.handleProductFilterLink(e, 'all')}>All Products</a>
                                    <a href={''} className={"ml-2"} onClick={e => this.handleProductFilterLink(e, 'FAA')}>FAA</a>
                                    <a href={''} className={"ml-2"} onClick={e => this.handleProductFilterLink(e, 'SD')}>SD</a>
                                    <a href={''} className={"ml-2"} onClick={e => this.handleProductFilterLink(e, 'ST')}>ST</a>
                                </div>
                            </div>
                            <div className={'col-6'}>
                                <Select
                                    clearable={true}
                                    deleteRemoves={true}
                                    backspaceRemoves={true}
                                    name="powderCoaterId"
                                    options={lookups.powderCoaters}
                                    multi={true}
                                    simpleValue={true}
                                    onChange={(val) => this.handleArrayFieldFilterUpdate(val, 'powderCoaterId')}
                                    value={filter.powderCoaterId}
                                    placeholder="Powdercoaters"
                                    disabled={!IsNullOrUndefined(filter.powderCoaterIdImpersonate)}
                                />
                                { !isPowderCoater && !isFabricMaker && <DebounceInput
                                    minLength={0}
                                    debounceTimeout={250}
                                    type={'text'}
                                    className={'form-control'}
                                    value={filter.sequenceNumber}
                                    onChange={(val) => this.handleFilterFieldChange(val.target.value, 'sequenceNumber')}
                                    name={"sequenceNumber"}
                                    placeholder={"PN"}
                                    style={{marginTop: '7px'}}
                                /> }
                                <Select
                                    className={"mt-2"}
                                    clearable={true}
                                    deleteRemoves={true}
                                    backspaceRemoves={true}
                                    name="projection"
                                    options={FilterProjections}
                                    multi={false}
                                    simpleValue={false}
                                    onChange={(val) => this.handleFilterFieldChange(val, 'projection')}
                                    value={filter.projection}
                                    placeholder="Projection"
                                />
                                <Select
                                    className={"mt-2"}
                                    clearable={true}
                                    deleteRemoves={true}
                                    backspaceRemoves={true}
                                    name="organisations"
                                    options={organisations}
                                    multi={true}
                                    simpleValue={true}
                                    onChange={(val) => this.handleArrayFieldFilterUpdateString(val, 'organisations')}
                                    value={filter.organisations}
                                    placeholder="Organisations"
                                />
                                <Select
                                    className={"mt-2"}
                                    clearable={true}
                                    deleteRemoves={true}
                                    backspaceRemoves={true}
                                    name="motors"
                                    options={motors}
                                    multi={true}
                                    simpleValue={true}
                                    onChange={(val) => this.handleArrayFieldFilterUpdateString(val, 'motors')}
                                    value={filter.motors}
                                    placeholder="Motors"
                                />
                                <div className={"mt-3"}>
                                    <CheckBoxControl 
                                                     labelText={'Include ALX received PC orders'}
                                                     value={filter.includePcOrderAlxReceived} onChange={(e) => {
                                        this.handleFilterFieldChange(e.target.checked, 'includePcOrderAlxReceived', () => {
                                            this.loadData();
                                        })
                                    }} name={'includePcOrderAlxReceived'}/>
                                </div>                                
                            </div>
                        </div>
                        {/*<div className={'row mb-4 mt-3'}>*/}
                        {/*    <div className={'col-6'}>*/}
                        {/*        <Select                                    */}
                        {/*            clearable={true}*/}
                        {/*            deleteRemoves={true}*/}
                        {/*            backspaceRemoves={true}*/}
                        {/*            name="products"*/}
                        {/*            options={products}*/}
                        {/*            multi={true}*/}
                        {/*            simpleValue={true}*/}
                        {/*            onChange={(val) => this.handleArrayFieldFilterUpdateString(val, 'products')}*/}
                        {/*            value={filter.products}*/}
                        {/*            placeholder="Products"*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*    <div className={'col-6'}>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </Aux> }
                    
                    
                    { viewMode==='fabric' && <div className={'row mb-4 mt-3'}>
                        <div className={'pi-col-md-12'}>
                            <a href={``}
                               onClick={e => {
                                   e.preventDefault();
                                   apiDownload(`/api/FabricDistibutors/ExportFabrics`, `${moment().format(DATE_SEARCH_FORMAT)}_fabric_distributors.zip`);
                               }}
                               data-toggle="tooltip" data-placement="top" title="Download Fabric Distributors"
                            ><i className={'fa fa-download mr-2'} />Download Fabric Distributors
                            </a>
                        </div>
                    </div> }
                </Aux>
             }

            <div className={'row'} style={{marginBottom: '15px'}}>
                <div className={'col-8'}>
                    <h4>Supplier Order Status</h4>
                    <h5><small><i>{loading ? "" : `Found ${items.length} orders / ${totalAwningCount} awnings`}</i></small></h5>
                </div>
                <div className={'col-4'} style={{textAlign: 'right'}}>
                    {!loading &&
                    <Aux>
                        {canViewAll && <button
                            onClick={(e) => {
                                e.preventDefault();
                                this.resetFilters();
                            }}
                            className="btn pi-btn pi-uppercase">
                            <i className="fa fa-times"/>&nbsp;Reset Filters
                        </button>}
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                this.loadData();
                            }}
                            className="btn pi-btn pi-uppercase ml-3 mr-3"                            
                        >
                            <i className="fa fa-retweet" />&nbsp;Refresh Results
                        </button>                       
                        
                    </Aux>
                    }

                    
                </div>
            </div>


            { canViewAll && (viewMode === 'planning') && <div className={'row mb-4'}>
                <div className={'col-2'}>
                    <CheckBoxControl labelText={'Show Cust. Ref'} value={filter.showCustRef}
                                     onChange={(val) => this.handleFilterFieldChange(val.target.checked, 'showCustRef', () => this.setPlanningColumns())}
                                     name={'showCustRef'}/>
                </div>
                <div className={'col-2'}>
                    <CheckBoxControl labelText={'Show Inv. No.'} value={filter.showInvoiceNumber}
                                     onChange={(val) => this.handleFilterFieldChange(val.target.checked, 'showInvoiceNumber', () => this.setPlanningColumns())}
                                     name={'showInvoiceNumber'}/>
                </div>
                <div className={'col-2'}>
                    <CheckBoxControl labelText={'Show Motors'} value={filter.showMotors}
                                     onChange={(val) => this.handleFilterFieldChange(val.target.checked, 'showMotors', () => this.setPlanningColumns())}
                                     name={'showMotors'}/>
                </div>
                {!loading && <div className={'col-8 text-right'}>
                    <button
                        onClick={this.exportCsv}
                        className="btn pi-btn-base pi-uppercase ml-3 mr-3"
                    >
                        <i className="fa fa-retweet"/>&nbsp;Export CSV
                    </button>
                </div>}
            </div>}

            {!canViewAll && user && <div className={'row'}>
                <div className={'col-12'}>
                    <h5>{user.organisation.description}</h5>
                </div>
            </div>}

            {loading ?
                <div>Loading... <i className={'fa fa-spinner fa-spin'}/></div> :
                <ReactTable
                    filterable={false}
                    sortable={true}
                    data={items}
                    columns={columns}
                    defaultSorting={[{id: "createdDate", desc: true}]}
                    getTheadTrProps={(state, rowInfo, column) => {
                        return {
                            style: {
                                textAlign: 'left'
                            }
                        }
                    }}
                />}

            <Switch>
                <Route exact path='/supplier/jobs/:orderId/:sheetName/:sheetIndex/:orderType/:download' component={SheetWrapperPage} />
            </Switch>
        </div>);
    }
}

JobsPage.propTypes = {
    organisations: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    supplierOrders: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        organisations: state.organisations,
        lookups: {...state.lookups,
            fabricMakers: [...state.lookups.fabricMakers, {label:"No Fabric Required", value: -1}]
        },
        supplierOrders: state.supplierOrders,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobsPage));