import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {apiRequest} from "../../utils/request";
import {
  DATE_SEARCH_FORMAT,
  DATE_SHORT_FORMAT,
  FABRIC_RECOVER_ORDER_STATUS_QUOTE,
  FabricRecoverOrderStatuses,
  INVOICE_TYPE_FABRICRECOVER,
  isNullEmptyOrWhitespace,
  obsfucate, OrderStatuses, UrlSegmentArray, UrlSegmentString,
} from "../../constants";
import moment from "moment";
import ReactTable from "react-table";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {some} from "lodash";
import {Link} from "react-router-dom";

const initState = {
  orders: [],
  columns: [],
  filter: {     
    customer: null,
    customerReferenceNumber: null,     
    pcColours: [],
    createdDateFrom: null,
    createdDateTo: null,
  },
};

const PcColours = [
  { label: "Anotec Mid Bronze Matt", value:"Anotec Mid Bronze Matt"},
  { label: "Anotec Silver Grey", value:"Anotec Silver Grey"},
  { label: "APO Grey Satin", value:"APO Grey Satin"},
  { label: "Barrister White", value:"Barrister White"},
  { label: "Basalt Matt GP208A", value:"Basalt Matt GP208A"},
  { label: "Basalt Satin", value:"Basalt Satin"},
  { label: "Black Gloss", value:"Black Gloss"},
  { label: "Black Matt", value:"Black Matt"},
  { label: "Black Satin", value:"Black Satin"},
  { label: "Charcoal Met Pearl 18796", value:"Charcoal Met Pearl 18796"},
  { label: "Charcoal Met Pearl 18797", value:"Charcoal Met Pearl 18797"},
  { label: "Citi Peal (Eternity)", value:"Citi Peal (Eternity)"},
  { label: "Citi Pearl", value:"Citi Pearl"},
  { label: "Classic Cream", value:"Classic Cream"},
  { label: "Cottage Green Satin", value:"Cottage Green Satin"},
  { label: "Dark Bronze 9068184K", value:"Dark Bronze 9068184K"},
  { label: "Deep Ocean", value:"Deep Ocean"},
  { label: "Dune", value:"Dune"},
  { label: "Flat White", value:"Flat White"},
  { label: "Headland Satin", value:"Headland Satin"},
  { label: "Interpon Sable Bass GN297A", value:"Interpon Sable Bass GN297A"},
  { label: "Ironstone Satin", value:"Ironstone Satin"},
  { label: "Jasper", value:"Jasper"},
  { label: "Jasper Satin", value:"Jasper Satin"},
  { label: "Manor Red Satin", value:"Manor Red Satin"},
  { label: "Matt Black", value:"Matt Black"},
  { label: "Misteltoe 51040 / White Birch 37131", value:"Misteltoe 51040 / White Birch 37131"},
  { label: "Monument", value:"Monument"},
  { label: "Monument 9066M Matt", value:"Monument 9066M Matt"},
  { label: "Monument Matt", value:"Monument Matt"},
  { label: "Monument Satin", value:"Monument Satin"},
  { label: "New Hamm Brown", value:"New Hamm Brown"},
  { label: "Off White + Deep Ocean", value:"Off White + Deep Ocean"},
  { label: "Off White 33732", value:"Off White 33732"},
  { label: "Olde Peter Satin", value:"Olde Peter Satin"},
  { label: "Olde Pewter", value:"Olde Pewter"},
  { label: "Oyster 36858", value:"Oyster 36858"},
  { label: "Pale Eucalypt Satin", value:"Pale Eucalypt Satin"},
  { label: "Paperbark", value:"Paperbark"},
  { label: "Paperbark Matt", value:"Paperbark Matt"},
  { label: "Paperbark Satin", value:"Paperbark Satin"},
  { label: "Pearl White", value:"Pearl White"},
  { label: "Pearl White Gloss", value:"Pearl White Gloss"},
  { label: "Primrose Gloss", value:"Primrose Gloss"},
  { label: "RAL 9010 White", value:"RAL 9010 White"},
  { label: "RAL White", value:"RAL White"},
  { label: "RAL White 9010", value:"RAL White 9010"},
  { label: "Riversand Matt", value:"Riversand Matt"},
  { label: "Sable Bass GN297A", value:"Sable Bass GN297A"},
  { label: "Shale Grey", value:"Shale Grey"},
  { label: "Shale Grey Matt", value:"Shale Grey Matt"},
  { label: "Shoji White", value:"Shoji White"},
  { label: "Silver Kinetic Pearl", value:"Silver Kinetic Pearl"},
  { label: "Sufmist Satin", value:"Sufmist Satin"},
  { label: "Surfmist", value:"Surfmist"},
  { label: "Surfmist Satin", value:"Surfmist Satin"},
  { label: "Vivica Bass", value:"Vivica Bass"},
  { label: "Wallaby Satin", value:"Wallaby Satin"},
  { label: "Weathered Copper", value:"Weathered Copper"},
  { label: "White Birch Gloss", value:"White Birch Gloss"},
  { label: "White Satin", value:"White Satin"},
  { label: "White Satin 1139S", value:"White Satin 1139S"},
  { label: "Wilderness Satin", value:"Wilderness Satin"},
  { label: "Woodland Grey", value:"Woodland Grey"},
  { label: "Woodland Grey 7255S Satin", value:"Woodland Grey 7255S Satin"},
  { label: "Woodland Grey Matt", value:"Woodland Grey Matt"},
  { label: "Woodland Grey Satin", value:"Woodland Grey Satin"},
  { label: "Zeus Charcoal", value:"Zeus Charcoal"},
];

const years = [
  { label: '2014', value:'2014'},
  { label: '2015', value:'2015'},
  { label: '2016', value:'2016'},
  { label: '2017', value:'2017'},
  { label: '2018', value:'2018'},
  { label: '2019', value:'2019'},
  { label: '2020', value:'2020'},
  { label: '2021', value:'2021'},
  { label: '2022', value:'2022'},
  { label: '2023', value:'2023'},
  { label: '2024', value:'2024'},
];

const customers = [
  { label: "ALX", value:"ALX"},
  { label: "Artech", value:"Artech"},
  { label: "Bayside", value:"Bayside"},
  { label: "BBPM", value:"BBPM"},
  { label: "Blinds in Style", value:"Blinds in Style"},
  { label: "Bright Blinds", value:"Bright Blinds"},
  { label: "Bush's Blinds", value:"Bush's Blinds"},
  { label: "Clearview", value:"Clearview"},
  { label: "Creative", value:"Creative"},
  { label: "Creative Canvas", value:"Creative Canvas"},
  { label: "Grovewood", value:"Grovewood"},
  { label: "Illawarra", value:"Illawarra"},
  { label: "Jace of Shades", value:"Jace of Shades"},
  { label: "Matiz", value:"Matiz"},
  { label: "Nuevue", value:"Nuevue"},
  { label: "Onyx", value:"Onyx"},
  { label: "Perfect Blinds", value:"Perfect Blinds"},
  { label: "Quality Blinds", value:"Quality Blinds"},
  { label: "Retail", value:"Retail"},
  { label: "Shade Industries", value:"Shade Industries"},
  { label: "ShadeCo", value:"ShadeCo"},
  { label: "Shades of Aust", value:"Shades of Aust"},
  { label: "Shadewell", value:"Shadewell"},
  { label: "Smart Canvas", value:"Smart Canvas"},
  { label: "Somfy", value:"Somfy"},
  { label: "Stan Bond", value:"Stan Bond"},
  { label: "Straitline", value:"Straitline"},
  { label: "Ulladulla", value:"Ulladulla"},
  { label: "Undercover", value:"Undercover"},
  { label: "Watson", value:"Watson"},
  { label: "Watsons", value:"Watsons"}, 
];

class LegacyOrdersSintesiPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {...initState};

    this.fetchOrders = this.fetchOrders.bind(this);   
    this.setColumns = this.setColumns.bind(this);
    this.updateFilterField = this.updateFilterField.bind(this);
    this.updateFilterFieldWithValue = this.updateFilterFieldWithValue.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.filterItems = this.filterItems.bind(this);
  }

  async fetchOrders() {

    let {filter} = this.state;
    
    let url = `/api/LegacyOrdersSintesi`;
    
    let orders = (await apiRequest(url)).data;
    this.setState({
      orders,
    }, () => {
      this.setColumns()
    });
  }

  setColumns() {
    this.setState({
      columns: [{id: 'sequenceNumber', Header: 'Prod. No.', 
        accessor: d => d.sequenceNumber,
        sortMethod: (a, b) => {
          let aa = a.indexOf(".") > 0 ? a.substring(0, a.indexOf(".")) : a;
          let bb = b.indexOf(".") > 0 ? b.substring(0, b.indexOf(".")) : b;
                  
          let aVal = parseInt(aa);
          let bVal =  parseInt(bb);

          return aVal < bVal ? -1 : 1;
        },
      },
        {
          id: 'createdDate', 
          Header: 'Created Date', 
          accessor: d => moment(d.createdDate).format(DATE_SHORT_FORMAT),
          sortMethod: (a, b) => {
            let aVal = moment(a, DATE_SHORT_FORMAT);
            let bVal = moment(b, DATE_SHORT_FORMAT);

            return aVal.isBefore(bVal) ? -1 : 1;
          },
        },
        {id: 'customer', Header: 'Customer', accessor: d => d.customer,},
        {id: 'customerReferenceNumber', Header: 'CRN', accessor: d => d.customerReferenceNumber,},
        {id: 'location', Header: 'Location', accessor: d => d.location,},        
        {id: 'width', Header: 'Width', accessor: d => d.width,},
        {id: 'projection', Header: 'Projection', accessor: d => d.projection,},
        {id: 'posts', Header: 'Posts', accessor: d => d.posts,},
        {id: 'comment', Header: 'Comment', accessor: d => d.comment,},
        {id: 'imwb', Header: 'IMWB', accessor: d => d.imwb,},
        {id: 'pcColour', Header: 'Colour', accessor: d => d.pcColour,},
        {id: 'fabric', Header: 'Fabric', accessor: d => d.fabric,},
        {id: 'led', Header: 'LED', accessor: d => d.led,},        
      ],
    });
  }

  updateFilterField(e){
    let {filter} = this.state;    
    
    filter[e.target.name] = e.target.value;
    
    this.setState({filter});
  }

  updateFilterFieldWithValue(val, fieldName = null){
    let {filter} = this.state;

    filter[fieldName] = val;

    this.setState({filter});
  }
  
  resetFilters(e){    
    e.preventDefault();   
    this.setState({filter: {
        customer: null,
        customerReferenceNumber: null,
        pcColours: [],
        createdDateFrom: null,
        createdDateTo: null,
      }});
  }

  filterItems(){
    let {orders, columns, filter} = this.state;

    if(!isNullEmptyOrWhitespace(filter.sequenceNumber)) {
      orders = orders.filter(x => !isNullEmptyOrWhitespace(x.sequenceNumber) && x.sequenceNumber.toLowerCase().includes(filter.sequenceNumber.toLowerCase()));
    }

    if(!isNullEmptyOrWhitespace(filter.customerReferenceNumber)){
      orders = orders.filter(x => !isNullEmptyOrWhitespace(x.customerReferenceNumber) && x.customerReferenceNumber.toLowerCase().includes(filter.customerReferenceNumber.toLowerCase()))
    }

    if(!isNullEmptyOrWhitespace(filter.customer)){
      orders = orders.filter(x => !isNullEmptyOrWhitespace(x.customer) && x.customer.toLowerCase().includes(filter.customer.toLowerCase()))
    }

    if(filter.pcColours.length > 0) {
      orders = orders.filter(x => some(filter.pcColours,
          y => x.pcColour.toLowerCase().includes(y.toLowerCase())));
    }
    
    if(!isNullEmptyOrWhitespace(filter.createdDateFrom)){
      orders = orders.filter(x =>  moment(x.createdDate).isAfter(filter.createdDateFrom));
    }

    if(!isNullEmptyOrWhitespace(filter.createdDateTo)){
      orders = orders.filter(x =>  moment(x.createdDate).isBefore(filter.createdDateTo));
    }
    
    return {orders};
  }
  
  async componentDidMount() {
    await this.fetchOrders();
  }

  render() {
    
    let {columns, filter} = this.state;
    let  {orders} = this.filterItems();

    return (
      <div>
        <div className={"row mt-2 mb-3"}>
          <div className={"col-12"}>
            <h2><Link to={"/legacyOrders/list"}>Legacy Database</Link> | Legacy
              Sintesi Database</h2>
          </div>
        </div>

        <div className={"row mt-2"}>
          <div className={"col-12"}>
            <form>
              <div className="form-group row">
                <div className="col-3">
                  <input type={'string'}
                         className={'form-control'}
                         value={filter.sequenceNumber || ""}
                         onChange={this.updateFilterField}                         
                         name={'sequenceNumber'}
                         placeholder={'Prod. No.'} />
                </div>
                <div className="col-3">
                  <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      multi={false}
                      labelText="Customer"
                      name="customer"
                      options={customers}
                      onChange={(val) => this.updateFilterFieldWithValue(val, "customer")}
                      simpleValue={true}
                      value={filter.customer || ""}
                      placeholder={'Customer'}
                  />
                </div>
                <div className="col-3">
                  <input type={'string'}
                         className={'form-control'}
                         value={filter.customerReferenceNumber || ""}
                         onChange={this.updateFilterField}
                         name={'customerReferenceNumber'}
                         placeholder={'CRN'} />
                </div>
                <div className={"col-3 text-right"}>                  
                    <button
                        onClick={this.resetFilters}
                        type="button"
                        className="btn pi-btn pi-uppercase">
                      <i className="fa fa-times"/>&nbsp;Reset
                    </button>                  
                </div>
              </div>

              <div className="form-group row">
                <div className="col-3">
                  <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      multi={true}
                      labelText="PC Colours"
                      name="pcColours"
                      options={PcColours}
                      onChange={(val) =>
                          this.updateFilterFieldWithValue(isNullEmptyOrWhitespace(val) ? [] : val.split(',').map(x => x), "pcColours")}
                      simpleValue={true}
                      value={filter.pcColours}
                      placeholder={'PC Colours'}
                  />
                </div>
                <div className={'col-sm-3'}>
                  <DatePicker
                      selected={filter.createdDateFrom}
                      selectsStart
                      startDate={filter.createdDateFrom}
                      endDate={filter.createdDateTo}
                      onChange={val => this.updateFilterFieldWithValue(val, 'createdDateFrom')}
                      className={'form-control'}
                      dateFormat={DATE_SHORT_FORMAT}
                      placeholderText={'Date From'}
                      dropdownMode={"scroll"}
                  /><br />
                  <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      multi={false}
                      labelText="Year From"
                      name="yearFrom"
                      options={years}
                      onChange={(val) => this.updateFilterFieldWithValue(isNullEmptyOrWhitespace(val) ? null :
                          moment(`${val}-01-01`), "createdDateFrom")}
                      simpleValue={true}
                      value={filter.createdDateFrom === null ? null : moment(filter.createdDateFrom).year().toString()}
                      placeholder={'Year From'}
                  />
                </div>
                <div className={'col-sm-3'}>
                  <DatePicker
                      selected={filter.createdDateTo}
                      selectsEnd
                      startDate={filter.createdDateFrom}
                      endDate={filter.createdDateTo}
                      onChange={val => this.updateFilterFieldWithValue(val, 'createdDateTo')}
                      className={'form-control'}
                      dateFormat={DATE_SHORT_FORMAT}
                      placeholderText={'Date To'}
                      dropdownMode={"scroll"}
                  /><br />
                  <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      multi={false}
                      labelText="Year To"
                      name="yearTo"
                      options={years}
                      onChange={(val) => this.updateFilterFieldWithValue(isNullEmptyOrWhitespace(val) ? null :
                          moment(`${val}-12-31`), "createdDateTo")}
                      simpleValue={true}
                      value={filter.createdDateTo === null ? null : moment(filter.createdDateTo).year().toString()}
                      placeholder={'Year To'}
                  /> 
                  
                </div>                                
              </div>             
              
            </form>
          </div>
        </div>
        
        <div className={"row mt-2 mb-4"}>
          <div className={"col-12"}>
            {orders.length >0 && <p><i>Found {orders.length} results {orders.length === 100 ? '(results are limited to the top 100)' : ''}</i></p> }
            <ReactTable                
                filterable={false}
                data={orders}
                columns={columns}
            />
          </div>
        </div>
        <div className={"row mt-4 mb-4"}>
          <div className={"col-12"}>&nbsp;</div>
        </div>
        
      </div>
    );
  }
}

LegacyOrdersSintesiPage.propTypes = {
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {

  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LegacyOrdersSintesiPage));